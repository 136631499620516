@import 'styles/variables.scss';

.HelperText {
  color: $colorNeutralDark50;
  font-size: 14px;
  margin: 0;
  padding-left: 4px;
  padding-top: 6px;

  &.isError {
    color: $colorSecondaryRed100;
  }
}

.FriendlyIdInputContainer {
  background: transparent;
  position: relative;

  .ContentEditable {
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    flex-wrap: wrap;
    max-height: 250px;
    min-height: 48px;
    outline: none;
    overflow: scroll;
    padding: 8px 12px;
    position: relative;

    //Error Icon
    > .icon-error {
      position: absolute;
      right: 6px;
    }

    //Theme Agnostic Error Styling
    &.isError {
      padding-right: 34px;
    }

    //Filled Theme Styling
    &.isFilled {
      border-bottom: solid 1px $colorNeutralDark30;
      margin-top: 15px;

      // Filled - Error
      &.isError {
        color: $colorSecondaryRed100;
        border-bottom-color: $colorSecondaryRed100;
        &:hover,
        &:focus,
        &.isContent,
        &:not(.isDisabled):focus,
        &:not(.isDisabled):not(:focus):hover {
          color: $colorSecondaryRed100;
          border-bottom-color: $colorSecondaryRed100;
          + label {
            color: $colorSecondaryRed100;
          }
        }
        + label {
          color: $colorSecondaryRed100;
        }
      }

      // Filled - Disabled
      &.isDisabled {
        background-color: $colorNeutralLight50;
        color: $colorNeutralDark75;
        cursor: not-allowed;
        pointer-events: none;
        + label {
          color: $colorNeutralDark50;
        }
        &.isContent {
          + label {
            color: $colorNeutralDark50;
          }
        }
        // Filled - Handle CaratRenderer Div
        > * {
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      // Filled - Hover Input
      &:not(.isDisabled):not(:focus):hover {
        background-color: $colorNeutralLight50;
      }

      // Filled - Focus Input
      &:not(.isDisabled):focus {
        border-bottom-color: $colorPrimaryBlue100;
        // Filled - handles carat placement
        &:not(.isContent) {
          align-content: flex-end;
        }
        // Filled - Transition Label
        + label {
          color: $colorPrimaryBlue100;
          left: 4px;
          top: -20px;
          > .LabelText {
            font-size: 12px;
          }
        }
      }

      // Filled - Content Present Label
      &.isContent {
        + label {
          color: $colorNeutralDark30;
          left: 4px;
          top: -20px;
          > .LabelText {
            font-size: 12px;
          }
        }
      }

      //Default Filled Label
      + label {
        left: 16px;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        text-align: left;
        top: 25%;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
        width: 100%;
        > .LabelText {
          visibility: visible;
          opacity: 1;
          margin: 0;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    //Outlined Theme Styling
    &.isOutlined {
      border-radius: 6px;
      border: 1px solid $colorNeutralDark30;

      // Outlined - Error
      &.isError {
        color: $colorSecondaryRed100;
        border-color: $colorSecondaryRed100;
        &:hover,
        &:focus,
        &.isContent,
        &:not(.isDisabled):focus,
        &:not(.isDisabled):not(:focus):hover {
          color: $colorSecondaryRed100;
          border-color: $colorSecondaryRed100;
          + label {
            color: $colorSecondaryRed100;
          }
        }
        + label {
          color: $colorSecondaryRed100;
        }
      }

      // Outlined - Disabled
      &.isDisabled {
        background-color: $colorNeutralLight50;
        border-color: $colorNeutralLight100;
        color: $colorNeutralDark75;
        cursor: not-allowed;
        pointer-events: none;
        + label {
          background: $colorNeutralLight50;
          color: $colorNeutralDark50;
        }
        &.isContent {
          + label {
            background: linear-gradient(0deg, $colorNeutralLight50 50%, white 50%);
            color: $colorNeutralDark50;
          }
        }
        // Outlined - Handle CaratRenderer Div
        > * {
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      // Outlined - Hover Input
      &:not(.isDisabled):not(:focus):hover {
        border-color: $colorNeutralDark100;

        + label {
          color: $colorNeutralDark100;
        }
      }

      // Outlined - Focus Input
      &:not(.isDisabled):focus {
        border-color: $colorPrimaryBlue100;
        // Outlined - Transition Label
        + label {
          background: linear-gradient(0deg, transparent 20%, white 0 80%);
          border-radius: 50%;
          color: $colorPrimaryBlue100;
          left: 12px;
          top: -12px;
          z-index: 1;
          > .LabelText {
            font-size: 12px;
          }
        }
      }

      //Outlined - Content Present Label
      &.isContent {
        + label {
          background: linear-gradient(0deg, transparent 25%, white 0 75%);
          border-radius: 50%;
          color: $colorNeutralDark30;
          left: 12px;
          top: -12px;
          z-index: 1;
          > .LabelText {
            font-size: 12px;
          }
        }
      }

      //Default Outlined Label
      + label {
        left: 12px;
        max-width: min-content;
        overflow: hidden;
        padding: 0 0 0 4px;
        pointer-events: none;
        position: absolute;
        text-align: left;
        top: 12px;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
        > .LabelText {
          margin: 0;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    .CaretRenderer {
      flex: 1;
      height: 22px;
      margin: 4px;
      width: auto;
    }

    //Styles for FriendlyIdTags
    .FriendlyIdTag {
      align-items: center;
      border-radius: 4px;
      border: 1px solid $colorNeutralLight100;
      color: $colorNeutralDark100;
      display: flex;
      font-size: 14px;
      justify-content: center;
      margin: 4px;
      padding: 4px 12px;
      width: 121px;
    }

    .RemoveFriendlyIdButton {
      padding-left: 10px;
    }
  }
}
