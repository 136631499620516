@import 'styles/variables.scss';

.TrackDetailsBanner {
  border-bottom: solid 1px $colorNeutralLight100;
  overflow: unset;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  color: $colorNeutralDark75;

  .hasActions {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.TrackDetailsBannerTrackLockContainer {
  height: 18px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  color: $colorNeutralDark50;

  .TrackDetailsBannerTrackLockIcon {
    font-weight: 700;
  }

  span {
    font-family: $bodyFont;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }
}

.TrackDetailsBannerInfoHeadingContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.TrackDetailsBannerTrackInfoHeading {
  width: max-content;

  h2 {
    font-size: 20px;
    line-height: 30px;
    font-family: $bodyFont;
    font-weight: 800;
  }

  .ActionsTooltipLinkButtonContainer {
    width: 142px;
  }
}

.TrackDetailsBannerTrackInfoProduct {
  margin-left: 12px;
  color: $colorDarkTeal100;
  font-size: 12px;
  line-height: 1em;
}

.TrackDetailsBannerTrackMeta {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 14px;
}

.TrackDetailsBannerMetaSection {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-family: $bodyFont;
  margin: 0;
  line-height: 18px;
}

.TrackDetailsBannerApplicants {
  display: flex;
}

.TrackDetailsBannerApplicantContainer {
  // only underline the name and not the spaces and commas
  &.hasActions:hover {
    text-decoration: none;

    .TrackDetailsBannerApplicantName {
      text-decoration: underline;
    }
  }
}

.TrackDetailsBannerQuickLinks {
  display: flex;
  padding-right: 10px;
  margin-top: 0;
  align-items: center;
  gap: 10px;
  min-width: 150px;
}

.TrackDetailsBannerMetaIcon {
  width: 16px;
  height: auto;
}
