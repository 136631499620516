@import 'styles/variables.scss';

.TaskMortgagesLiens {
  display: flex;
  flex-direction: column;
  flex: 2 1 0;
  gap: 24px;
  height: 100%;
  overflow-y: scroll;
  padding: 32px 32px 72px 32px;
}

.PayoffDetails {
  white-space: pre-wrap;
}
