@import 'styles/variables.scss';

.UploadDocumentProcessing {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 120px;
  height: 100%;
  overflow: scroll;
}

.UploadDocumentProcessingDocumentsFileList {
  border: 1px solid $colorNeutralLight100;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  .icon-error {
    color: $colorSecondaryRed100;
  }
  .icon-check-mdc {
    color: $colorPrimaryGreen100;
  }
  .htco-DataTable {
    overflow: scroll;
    .rdt_Table {
      min-height: 100px;
    }
    .rdt_TableRow {
      transition: 0.25s;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.UploadDocumentProcessingDocumentsFileListHeading {
  padding-left: 16px;
  padding-top: 16px;
}

.UploadDocumentsProcessingDocumentsFileListDivider {
  width: 100%;
}

.UploadDocumentProcessingFileNameContainer {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.UploadDocumentProcessingFileNameSize {
  margin-top: 2px;
}

.UploadDocumentProcessingPinIcon {
  color: $colorNeutralDark30;
}

.UploadDocumentProcessingProgressBarContainer {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 32px;
  margin-bottom: 32px;
  .htco-ProgressBar {
    margin-top: 8px;
  }
}

.UploadDocumentProcessingButton {
  position: fixed;
  background-color: white;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-right: 40px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}
