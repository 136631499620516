@import 'styles/variables.scss';

.session-security-modal {
  top: 25%;
  padding: 16px;
  background: #fff;
  color: #333;
  z-index: 1201;
  overflow: auto;
  text-align: center;
  h3 {
    color: $colorHometapBlue;
    padding: 0;
    margin: 20px;
  }
}
