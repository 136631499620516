@import 'styles/variables.scss';

.TwoItemFormRow {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.TwoItemFormRowItem {
  width: 100%;
}

.SeniorLienSelect__menu {
  z-index: 101 !important;
}

.SeniorLienDatePicker {
  width: 100%;

  .react-datepicker-wrapper,
  .htco-DatePickerInput {
    width: inherit;
  }

  .htco-DatePickerHelperText {
    display: inline-block;
  }
}

.MortgageLienRangeField.htco-error {
  &:has(input:focus) {
    .icon-error {
      opacity: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: block;
    }
  }

  &:has(input:hover:not(:focus)) {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .icon-error {
    opacity: 1;
  }
}

.MortgageLienBlockText {
  margin-top: 16px;
}

.MortgageLienDivider {
  border-bottom: 1px solid $colorNeutralLight100;
}

.MortgageLienDatePicker {
  width: 100%;

  .react-datepicker-wrapper,
  .htco-DatePickerInput {
    width: inherit;
  }
}

.EscalatedBy {
  margin-bottom: 20px;
}

.TaskDetailContent {
  margin-top: 32px;

  ul {
    line-height: 32px;
  }

  li {
    list-style: disc;
    list-style-position: outside;
    margin-left: 1em;
  }
}

.FormFieldsGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 24px;
}

.PropertyConditionSelector {
  margin-bottom: 25px;
}

.PropertyConditionLabel {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}

.SectionDivider {
  margin-bottom: 24px;
  border-bottom: 1px solid $colorNeutralLight100;
}

.ReassignBlock {
  margin-bottom: 24px;
}
