@import 'styles/variables.scss';

$headerSpacing: 25px;
$createButtonSpacing: 50px;
$bottomMenuHeight: 160px;
$bottomMenuShadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
$lightGreyBorder: 1px solid $colorLightGray3;

@mixin bottomMenuBaseStyles {
  min-height: $bottomMenuHeight;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $colorWhite;
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.Todos {
  padding-top: $headerSpacing;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.TodosTableHeader {
  font-family: $bodyFont;
  font-weight: bold;
  margin-bottom: $headerSpacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TodosTableHeading {
  font-family: $bodyFont;
  font-weight: bold;
}

.TodosCountInfo {
  margin-right: 1.5em;
}

.TodosTable {
  &.isEmpty {
    .rdt_Table {
      min-height: auto;
    }
  }

  .rdt_ExpanderRow {
    border-bottom: $lightGreyBorder;
  }

  .rdt_TableRow {
    &:last-child {
      border-bottom: $lightGreyBorder;
    }

    &:nth-of-type(even) {
      background-color: $colorLightGray1;

      & + .rdt_ExpanderRow {
        .htco-Paper.htco-isMedium {
          background-color: $colorWhite;
        }
      }
    }
  }

  .rdt_TableCell p:first-child {
    white-space: normal;
  }
}

.TodosTableTodoColumnCopy {
  margin: 0;
}

.TodosTableTodoColumnCopyNotes {
  display: block;
  margin-top: 5px;
  white-space: normal;
  max-width: 450px;
}

.TodosTableCreateLink {
  margin-top: $createButtonSpacing;
}

.TodosTableNoTodos {
  margin: 0;
  margin-top: calc(#{$createButtonSpacing} - #{$headerSpacing});
  margin-right: auto;
  font-family: $bodyFont;
}

.TodosSelectionListGroup {
  .htco-AccordionContent {
    padding-top: 0;
  }

  &:last-of-type {
    margin-bottom: calc(#{$bottomMenuHeight} + 20px);
  }
}

.TodosSelectionListGroupHeading {
  font-family: $bodyFont;
}

.TodosSelectionListGroupItemContainer {
  padding: 0 20px;
}

.TodosSelectionListGroupItem {
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $colorLightGray3;
}

.TodosSelectionListGroupItemHeading {
  font-family: $bodyFont;
  padding: 5px 0;
  margin: 0;
}

.TodosSelectionListTodoButton {
  align-self: flex-start;
  margin: 0;

  &.isAddButton {
    &[disabled] {
      border-color: $disabledHometapBlue;
      color: $disabledHometapBlue;
    }
  }

  &.isRemovalButton {
    &:hover {
      background-color: $colorRed;
      border-color: $colorRed;
    }

    border-color: $colorRed;
    color: $colorRed;
  }
}

.TodosSelectionListGroupAlreadyAssigned {
  font-size: 12px;
}

.TodosSelectionListBottomMenu {
  @include bottomMenuBaseStyles;
  box-shadow: $bottomMenuShadow;

  .htco-Button {
    margin-top: 0;
    margin-bottom: 0;
  }

  .htco-Button[disabled] {
    background-color: $disabledHometapBlue;
    border: none;
  }
}

.TodosSelectionListBottomMenuSelectedTodosHeading {
  font-family: $bodyFont;
  font-weight: bold;
  margin: 0;
}

.TodosSelectionListBottomMenuSelectedTodosLabels {
  margin-top: 10px;
  margin-bottom: 0;
}

.TodosSelectionListBottomMenuButtons {
  padding-left: 20px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.TodosSelectionListBottomMenuReviewButton {
  min-width: 201px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TodosSelectionListNoTodos {
  font-family: $bodyFont;
}

.TodosAssignmentList {
  padding-bottom: $bottomMenuHeight;

  &.isLastChildMarginApplied {
    .TodosAssignmentItem {
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
}

.TodosAssignmentHomeownerTodoCount {
  border-bottom: $lightGreyBorder;
  margin-bottom: $headerSpacing;
}

.TodosAssignmentHomeownerTodoCountHeading {
  font-family: $bodyFont;
  font-weight: bold;
}

.TodosAssignmentItem {
  padding-bottom: 16px;
  margin-top: 32px;
  border-bottom: $lightGreyBorder;
}

.TodosAssignmentItemRow {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.TodosAssignmentReasonSelect,
.TodosAssignmentReasonTextareaContainer {
  .htco-Label {
    margin-top: 0;
    font-size: 14px;
  }
}

.TodosAssignmentReasonTextareaContainer {
  margin-top: 16px;
}

.TodosAssignmentReasonTextarea {
  resize: vertical;
}

.TodosAssignmentBottomButtonRow {
  @include bottomMenuBaseStyles;
  justify-content: space-between;

  &.isBoxShadowApplied {
    box-shadow: $bottomMenuShadow;
  }
}

.TodosConfirmSendReminderModalHeading {
  font-family: $bodyFont;
  margin-bottom: $headerSpacing;
  font-size: 28px;
  line-height: 39px;
  font-weight: bold;
}

.TodosConfirmSendReminderModalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;

  button {
    margin: 0;
  }
}

.TodosConfirmSendReminderModalCancel {
  margin-right: 20px;
}

.TodosAssignmentOtherInsuranceInformation {
  padding: 16px 0 0;
}
