@import 'styles/variables.scss';

.SelectionYesNoSetLabel {
  white-space: break-spaces;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.SelectionYesNoSetInfoTooltip {
  .htco-Tooltip {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.8);
    padding: 4px 8px;
    color: $colorNeutralLight30;
  }

  .htco-Tooltip[data-popper-placement^='bottom'] .htco-TooltipArrow {
    &:before,
    &:after {
      display: none;
    }
  }
}

.SelectionYesNoSetInfoTooltipImage {
  display: flex;

  img {
    width: 16.5px;
    height: 16.5px;
  }
}

.SelectionYesNoSetInfoIcon {
  color: $colorPrimaryBlue100;
}

.SelectionYesNoSetFormItem {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  &.isWithMarginBottom {
    margin-bottom: 24px;
  }

  .htco {
    &-SelectionSetItem {
      margin: 0;
      height: 48px;
      min-height: 48px;
      padding: 0 24px;
      span {
        justify-content: center;
      }
    }

    &-SelectionSetWrapper {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
    }
  }
}
