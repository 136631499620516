@import 'styles/variables.scss';

.AVStatusSmall {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  min-width: 0;
  background-color: $colorSecondaryRed15;
  color: $colorSecondaryRed100;
  text-overflow: ellipsis;
  overflow: hidden;

  &.isEncrypted {
    background-color: $colorSecondaryYellow15;
    color: #b88c13;
  }
}
