@import 'styles/variables.scss';

.SummaryDivider {
  border-bottom: 1px solid $colorNeutralDark50;
  height: 0;
  width: 100%;
}

.SummaryList {
  margin: 8px 0;
}
