@import 'styles/variables.scss';

.FormWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.FormHeader {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
}

.FormTitle {
  font-family: Tiempos Headline;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.DeleteIcon {
  color: $colorSecondaryRed100;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.htco-Icon {
    font-size: 18px;
  }
  &:hover {
    cursor: pointer;
  }
  &.isDisabled {
    opacity: 0.5;
    cursor: default;
  }
}

.AreYouSureText {
  margin-top: 30px;
}
