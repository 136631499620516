@import 'styles/variables.scss';

//The height and page layout for the helperText is very strange. This css fixes it.
.CustomInsurancePoliciesSelector {
  & + .htco-MuiSelectLabel + p {
    height: auto;
  }
}

.CustomInsurancePoliciesSelector__menu {
  z-index: 2 !important;
}

.InsurancePoliciesInformationBlock {
  margin-top: 24px;
}
