@import 'styles/variables.scss';

.InvestmentCalculatorControllerInput {
  flex: 1 0 auto;
  height: 40px;
  max-width: 145px;
}

.InvestmentCalculator {
  .InvestmentCalcInputsSection {
    margin-right: 100px;
  }

  &.isOverlay {
    .InvestmentCalcMaxPotentialInvestment,
    .InvestmentCalcInputsContainer {
      width: 100%;
    }

    .InvestmentCalcInputsSection {
      margin-right: unset;
    }
  }
}
