@import 'styles/variables.scss';

.MaintenanceModeScreenOverlayContainer {
  background-color: white;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 20px;

  @include respond('sm') {
    padding: 120px 40px;
    justify-content: start;
  }

  @include respond('xs') {
    padding: 80px 24px;
  }
}

.MaintenanceModeScreenOverlayContentContainer {
  display: flex;
  max-width: 784px;

  flex-direction: column;
  align-items: center;
  gap: 24px;

  @include respond('sm') {
    max-width: 791px;
  }
}

.MaintenanceModeScreenOverlayBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.MaintenanceModeScreenOverlayContentHeader {
  color: $colorNeutralDark100;
  text-align: center;
  margin: 0;
}

.MaintenanceModeScreenOverlayContainerImage {
  max-width: 668px;

  @include respond('sm') {
    max-width: 293px;
  }
}

.MaintenanceModeScreenOverlayContentDescription {
  color: $colorNeutralDark75;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  margin: 0;

  @include respond('md') {
    font-size: 18px;
  }
}
