.PanAndZoomImageContainer {
  width: 100%;
  height: 100%;
}

.PanAndZoomImageTransformComponentWrapper {
  // override the default styles set by the library
  width: 100% !important;
  height: 100% !important;
}

.PanAndZoomImageContainerImage {
  transition: 0.5s ease;
}
