@import 'styles/variables.scss';

.AlertError {
  border-radius: 12px;
  border-width: 1px;
  margin-bottom: 20x;
  padding: 16px 24px;
  // HACK: Adding an alpha to $colorSecondaryRed100 without adding it to components
  background-color: rgba(180, 26, 26, 0.05);
  color: $colorNeutralDark75;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;

  .htco-Icon {
    color: $colorRed;
    margin-right: 8px;
    font-size: 18px;
  }
}
