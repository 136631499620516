@import 'styles/variables.scss';

.AppraisalsControllerPage {
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 32px 108px 140px;
}

.AppraisalsControllerPaper {
  border-radius: 8px;
  box-shadow: none !important;
  overflow: visible;
}

.AppraisalsReggoraContainer {
  border: 1px solid $colorNeutralLight100;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;

  .htco-DataTable {
    :first-child {
      overflow: visible;
    }
  }

  &.htco-DataTable {
    margin-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
    border-color: $colorNeutralLight100;

    &.rdt_TableRow {
      transition: 0.25s;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }

    &.rdt_TableHeadRow {
      transition: 0.25s;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }

    &.rdt_TableHead {
      transition: 0.25s;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }
  }
}

.AppraisalsStatusWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.AppraisalsReggoraTitle {
  padding: 8px 16px;
  font-size: 18px;
  line-height: 30px;
  color: $colorNeutralDark100;
  font-weight: 700;
  font-family: Mulish;
}

.AppraisalsReggoraStatus {
  justify-content: end;
}

.AppraisalsReggoraHeader {
  color: $colorNeutralDark100;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  height: auto;
  border-bottom-color: $colorNeutralLight100;
}

.AppraisalsNewAppraisalButton {
  margin-right: 16px;
  margin-left: 24px;
}

.AppraisalsNewAppraisalButtonInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AppraisalsNewAppraisalButtonText {
  margin-left: 10px;
}

.AppraisalsTableText {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: $colorNeutralDark100;
}

.AppraisalsTableSmallText {
  font-size: 12px;
  font-weight: 400;
}

.AppraisalsReggoraTableHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.AppraisalsNoOrders {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.AppraisalsIconBackground {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 1px solid $colorLightGray3;
  padding: 24px;
  margin-bottom: 16px;
}

.AppraisalsHomeSmile {
  margin-left: 0px;
  width: 24px;
  height: 24px;
}

.AppraisalsNewAppraisalTitle {
  padding: 8px 16px;
  font-size: 18px;
  line-height: 30px;
  color: $colorNeutralDark100;
  font-weight: 700;
  font-family: Mulish;
}

.AppraisalsMenuDropdown {
  margin: 8px 5px 0px 5px;
}

.AppraisalsTableContainer {
  .htco-DataTable .rdt_TableRow:not(:last-of-type),
  .htco-DataTable .rdt_TableHeadRow:not(:last-of-type) {
    border-bottom-color: $colorNeutralLight100;
    border-top-color: $colorNeutralLight100;
  }

  .htco-DataTable .rdt_TableRow:not(:last-of-type),
  .htco-DataTable .rdt_TableHeadRow {
    border-bottom-color: $colorNeutralLight100;
    border-top-color: $colorNeutralLight100;
  }

  &.htco-DataTable {
    margin-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
    border-color: $colorNeutralLight100;

    &.rdt_TableRow:not(:last-of-type) {
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }

    :first-child {
      overflow: visible;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }

    &.rdt_TableRow {
      transition: 0.25s;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }

    &.rdt_TableHeadRow {
      transition: 0.25s;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }

    &.rdt_TableHead {
      transition: 0.25s;
      border-bottom-color: $colorNeutralLight100;
      border-top-color: $colorNeutralLight100;
    }
  }

  .rdt_TableCol {
    padding-bottom: 12px;
  }
}

.AppraisalsTable {
  & > [role='heading'] {
    & > div {
      font-weight: 700;
    }
  }
}

.AppraisalsTypeInfo {
  display: grid;
  padding-top: 16px;
  padding-bottom: 16px;
}

.AppraisalsLinksWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .AppraisalOfRecordIcon {
    color: $colorYellow;
  }
}

.AppraisalsSeparator {
  margin: 0px;
  border: 0.5px solid $colorNeutralLight100;
  color: $colorNeutralLight100;
}

.AppraisalsCallOut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: $colorPrimaryBlue5;
  border-radius: 6px;
  padding: 16px 24px;
  border-width: 1px;
  border-color: $colorPrimaryBlue15;
  gap: 16px;

  @include respond('md') {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}
