@import 'styles/variables.scss';

.CreateDocumentSetContentContainer {
  height: 87vh;
  margin-bottom: 136px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 32px;
  overflow: scroll;

  > .htco-MuiSelect {
    > * > div[class*='menu'] {
      z-index: 10;
    }
  }
}

.CreateDocumentSetContentContainerSelect {
  width: 100%;
}

.CreateDocumentSetStaticButtonBar {
  position: absolute;
  height: 136px;
  left: 0px;
  bottom: 0px;
  display: flex;
  width: 100%;
  flex-direction: row;
  background: white;
  border-top: 1px solid $colorNeutralLight100;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
}

.CreateDocumentSetContentContainerHeader {
  color: $colorNeutralDark100;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.CreateDocumentSetStaticButtonBarLittleText {
  font-weight: 400;
  font-size: 12px;
  color: $colorNeutralDark50;
  line-height: 23px;
  margin: 5px 0;
  padding: 0;
}

.CreateDocumentSetStaticButtonBarContainer {
  display: flex;
}

.CreateDocumentSetStaticButtonBarNotify {
  margin-right: 8px;
}

.CreateDocumentSetContentFileList {
  border: 1px solid $colorNeutralLight100;
  border-radius: 12px;

  .htco-DataTable {
    width: 100%;
    overflow: scroll;
    margin-bottom: 8px;
    z-index: 0;

    .rdt_Table {
      min-height: 50px;
    }
  }
}

.CreateDocumentSetNoDocuments {
  margin-top: 16px;
}

.CreateDocumentSetNameContainerTextInput {
  width: 80%;
}

.CreateDocumentSetContentDataTableHeader {
  padding-top: 16px;
  padding-left: 16px;
}

.CreateDocumentSetRemoveButton {
  background-color: $colorNeutralLight50;
  color: black;
  padding: 4px;
  border-radius: 50%;
}

.CreateDocumentSetRadioButtonSet {
  transform: translateX(-8px);
}

.CreateDocumentSetSubmitLoader {
  transform: translateY(-5px);
}

.CreateDocumentSetTooltip {
  margin-left: 4px;
}

.createDocumentSetRemoveDocumentButton {
  z-index: 9999;
  cursor: pointer;
}
