@import '../../styles/variables.scss';
@import '../../styles/base.scss';

// Demo Button

.DemoButtonWrapper {
  .htco-Button:not(:disabled) {
    background-color: $colorYellow;
    color: $colorBlack;
    border: none;
    margin: 0;
  }
}

.DemoButtonPageLevel {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
