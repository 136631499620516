@import 'styles/variables.scss';

%documentFileCannotBeViewed {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DocumentFileCannotBeViewed {
  @extend %documentFileCannotBeViewed;
  overflow-y: auto;
}

.AVStatusWrapper {
  width: 100%;
  white-space: break-spaces;
  background-color: white;
  border-bottom: 1px solid $colorNeutralLight100;
}

.DocumentFileContent {
  @extend %documentFileCannotBeViewed;
  justify-content: center;
  min-height: 160px;
}
