@import 'styles/variables.scss';

.TrackApplicationReviewController {
  inset: 0;
  max-width: unset;
  padding: 0;
  position: absolute;
}

.TrackApplicationReviewColumn {
  border-right: 1px solid $colorNeutralLight100;
  display: inline;
  max-height: 100%;
  max-width: unset;
  overflow: scroll;
  padding: 0;
}

.CenterAlign {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.TrackApplicationReviewAlertsContainer {
  &:not(:empty) {
    margin-left: 64px;
    margin-right: 40px;
    padding-top: 33px;
    padding-bottom: 33px;
    width: calc(100% - 104px);
    border-bottom: 1px solid $colorNeutralLight100;
  }
}

.TrackApplicationReviewSidebarDetailsContainer {
  flex-wrap: nowrap;
  padding: 32px 32px 0 32px;
  max-width: unset;
  height: 100%;
  overflow-y: scroll;
}
