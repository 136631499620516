@import 'styles/variables.scss';

.DataListContainer {
  margin-bottom: 40px;
}

.DataListTitle {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: $colorNeutralDark100;
}

.DataListItem {
  gap: 4px;
  margin-top: 16px;
  position: relative;
  flex-direction: column;

  &.isRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.DataListItemKey {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $colorDarkGray;
}
