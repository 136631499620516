@import 'styles/variables.scss';

.DocumentsControllerContainer {
  width: 100%;
}

.DocumentsControllerCheckBox {
  transform: translateY(-2px);
  &.selectAll {
    .htco-CheckboxCustom {
      border: 1px solid $colorPrimaryBlue100;
    }
  }
  &:not(:checked) {
    .htco-CheckboxCustom {
      border: 1px solid $colorNeutralDark30;
    }
  }
}

.DocumentsControllerLink {
  color: $colorPrimaryBlue100;
  display: flex;
  width: 100%;

  &:hover span {
    text-decoration: underline;
  }
}

.DocumentsControllerLinkIcon {
  margin-left: 8px;
  color: $colorNeutralDark50;
}

.DocumentsControllerLinkName {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.DocumentsControllerDataTable {
  &.htco-DataTable {
    margin-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
    .checkbox {
      height: 18px;
      width: 18px;
      border: 2px solid $colorNeutralDark30;
      border-radius: 2px;
    }
    .rdt_TableRow {
      transition: 0.25s;
      &:hover {
        background-color: #f4f7ff;
      }
    }
  }
}

.DocumentsControllerApplicant {
  font-size: 14px;
  line-height: 23px;
}

.DocumentsConfirmationModal {
  .htco-ModalConfirmBody {
    padding: 0;
  }

  .htco-ModalContainer {
    padding: 56px 32px 32px;
  }

  .htco-ModalClose {
    top: 25px;
    right: 20px;
    & > .htco-IconButton {
      color: $colorNeutralDark100;
    }
  }

  .htco-ModalConfirmHeader {
    font-size: 28px;
    padding-bottom: 24px;
  }

  .htco-ModalConfirmFooter {
    padding-top: 32px;
  }

  .htco-Button {
    font-size: 16px;
    font-weight: 700;
    height: 56px;
    &.htco-ModalConfirmCancel {
      text-decoration: unset !important;
      margin-right: 24px !important;
    }
    &.htco-isDanger {
      border: none;
      margin: 0;
      border-radius: 6px;
    }
  }
}

.ModalConfirmDocumentWrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ModalConfirmDocumentName {
  font-weight: 700;
}

.ModalConfirmDocumentNameWithEllipsis {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ModalConfirmDocumentBodyDescription {
  margin-top: 24px;
  margin-bottom: 0;
}

.ModalConfirmDocumentBodyConfirmText {
  margin-top: 0;
  margin-bottom: 0;
}

.ModalConfirmDocumentBodyConfirmDescriptionHideCount {
  margin-top: 0;
}

.ModalConfirmDocumentBodyConfirmTextBottom {
  margin-bottom: 24px;
}

.DocumentSetRequestLogContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 64px;
  margin-top: 56px;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
}

.DocumentSetRequestLogFailedIcon {
  color: $colorSecondaryRed100;
}

.DocumentsViewAccess {
  margin-top: 42px;
}
