@mixin bodyFont($size: 'md') {
  line-height: 1.5;
  @if $size == 'sm' {
    font-size: $fontSizeSm;
  } @else if $size == 'lg' {
    font-size: $fontSizeLg;
  } @else {
    font-size: $fontSizeBody;
  }
}
