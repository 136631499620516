@import 'styles/variables.scss';

.ResetApplicantVerificationButton.htco-Button.htco-isLink {
  font-weight: 100;
}

.ApplicantResendButton {
  width: fit-content;
  margin: 0;
}

.InlineLabelValue {
  display: flex;
  white-space: pre-wrap;

  span {
    padding-top: 2px;
  }
}

.FicoIconOK {
  color: #5cbb8e;
}

.FicoIconFail {
  color: #c75353;
}

.UnconfirmedEmail {
  color: $colorSecondaryRed100;
}
