@import 'styles/variables.scss';

.TaskConditionsHtmlRenderer {
  p {
    margin: 0;
  }
}

.TaskConditionsCheckbox {
  align-items: flex-start;

  .htco-CheckboxDisplayLabel {
    p {
      line-height: 24px;
    }
  }

  .htco-CheckboxCustom {
    border-width: 2px;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:after {
      top: 2px;
      left: 2px;
    }
  }
}

.TaskConditionsCheckboxList {
  & label:not(:last-child) {
    margin-bottom: 24px;
  }
}

.TaskConditionsListItem {
  display: flex;

  &::before {
    content: '\25cf';
    padding-right: 8px;
    font-size: 14px;
    color: $colorNeutralDark100;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
