@import 'styles/variables.scss';

.detailItemContainer {
  display: inherit;
  flex-direction: inherit;
  flex: inherit;
  gap: inherit;
  height: inherit;
  padding: 0px 0px 16px 0px;
}

.detailItemContainer:last-child {
  padding-bottom: 0px;
}

//The default styling for the `SelectionSet` htco-component breaks the gap spacing.
.SelectorElement {
  width: 100%;
  & > .htco-Label {
    margin-top: 0px;
  }
}
//Set selector does not have a default disabled style. This implements one.
.disabledSelectorElement {
  & > .htco-SelectionSetWrapper {
    & > .htco-SelectionSetItem {
      background-color: $colorNeutralLight50;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

@mixin full-width-date-picker {
  width: 100%;
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }
}
//Styling for when two forms fields need to exist on the same row
.TwoItemFormRow {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  //Forces the DatePicker to take up a 50% of space on two item form row
  .FullWidthDatePicker {
    @include full-width-date-picker;
  }
}

//Styling for a single date field per row
.FullWidthDatePicker {
  @include full-width-date-picker;
}
