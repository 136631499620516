@import 'styles/variables.scss';

.opportunityDetailsSidebarButton.htco-Button.htco-isLink {
  border: 0px;
  padding: 0px;
  font-weight: 100;
}

.opportunityDetailsSidebarButton.htco-Button.htco-isLink:focus:not(.htco-noFocusRing) {
  border: 0px;
  padding: 0px;
  font-weight: 100;
}

.htco-Table.additionalTracksTable {
  td,
  th {
    border: 1px solid $colorNeutralLight100;
  }
}
