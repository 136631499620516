@import 'styles/variables.scss';

.TaskListLoadingWrapper {
  min-width: 150px;
}

.TaskList {
  height: 100%;
  min-width: 350px;
  width: 398px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding-bottom: 16px;
}

.TaskListTasks {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 32px 0 0;
}

.TaskListAccordions {
  height: inherit;
  overflow-y: auto;
  margin-top: 76px;
  padding: 0 32px;
}

.TaskListAccordion {
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .htco-AccordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 20px 12px 16px;
  }

  .htco-AccordionHeaderContent {
    color: $colorNeutralDark100;
    font-size: 18px;
    font-weight: 400;
  }

  .htco-AccordionHeader > .htco-AccordionExpandIcon {
    border: 0.84375px solid $colorNeutralDark100;
    border-bottom: transparent;
    border-left: transparent;
  }

  .htco-AccordionContent.htco-isWithArrowContent {
    padding-bottom: 0;
  }
}

.TaskListAccordionButton {
  display: flex;
  flex-direction: row-reverse;
  font-weight: 700;
  align-items: center;
  justify-content: left;

  &.htco-Button.htco-hasIcon.htco-isLink {
    text-decoration: none !important;
    padding: 12px 18px;
    line-height: 22px;
    font-size: 14px;
    border: none;
  }

  &.htco-hasIcon.htco-isLink i.icon-plus {
    top: 0;
    font-size: 14px;
    padding-left: 10px;
  }
}

.TaskListDropdownContainer {
  width: 100%;
  position: absolute;
  padding: 0 32px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.TaskListDropdown {
  flex: auto;
  background-color: inherit;

  .htco-MenuDropdownTrigger {
    height: 52px;
    background-color: $colorNeutralLight50;
  }

  .htco-MenuDropdownContent {
    transform: translate3d(0px, 54px, 0px) !important;
  }

  .htco-MenuDropdownTrigger {
    font-size: 18px;
    font-weight: 500;
  }

  .htco-MenuDropdownChevron {
    font-size: 20px;
  }
}

.TaskListDropdownItem {
  display: flex;
  width: 100%;
  align-items: center;
  height: 48px;
  line-height: 1;
  padding: 12px 16px;
  color: $colorBodyText;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background: fade-out($colorPrimaryBlue75, 0.9);
  }

  &.isActive {
    display: flex;
    justify-content: space-between;
    background: $colorPrimaryBlue15;
  }

  &.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.TaskListDropdownItemLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TaskListDropdownItemIcon {
  color: $colorPrimaryBlue100;
}

.TaskListButton {
  height: 40px;
  margin: 24px 0 0;
  width: calc(100% - 96px);
  font-weight: 700;
  align-self: center;
}

.TaskListError {
  padding-right: 32px;
  padding-left: 32px;
}

.TaskListTooltip {
  .htco-Tooltip {
    inset: -11px auto auto 0px !important;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.8);
    padding: 4px 8px;
    color: $colorNeutralLight30;
  }

  .htco-Tooltip[data-popper-placement^='bottom'] .htco-TooltipArrow {
    &:before,
    &:after {
      display: none;
    }
  }

  & > span[role='button'] {
    display: flex;
  }
}

.TaskGroupItem-exit {
  height: 49px; /* Element's height should be defined for smooth movement of elements in the list */
  opacity: 1;
  overflow: hidden;
}

.TaskGroupItem-exit-active {
  height: 0;
  opacity: 0;
  transition:
    height 400ms ease-in,
    opacity 300ms ease-out;
}
