@import 'styles/variables.scss';

.HtmlRenderer {
  line-height: 0;

  table tr th {
    color: black;
    font-weight: bold;
  }

  td {
    max-width: 300px;
    min-width: 200px;
  }

  ul {
    padding-left: 24px;
    line-height: 0;
    list-style: inside;

    & > li {
      line-height: 32px;
    }
  }

  h5 {
    margin-top: 24px;
    line-height: 24px;
  }

  p {
    line-height: 32px;
    white-space: initial;
  }
}

table.HtmlRenderer tr th {
  color: black;
  font-weight: bold;
}

td.HtmlRenderer {
  max-width: 300px;
  min-width: 200px;
}

ul.HtmlRenderer {
  padding-left: 24px;
  line-height: 0;
  list-style: inside;

  & > li {
    line-height: 32px;
  }
}

h5.HtmlRenderer {
  margin-top: 24px;
  line-height: 24px;
}

p.HtmlRenderer {
  line-height: 32px;
  white-space: initial;
}
