@import 'styles/variables.scss';

.UploadDocument {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.UploadDocumentsTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 40px;
}

.UploadDocumentContainer {
  overflow-y: scroll;
  padding-bottom: 120px;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;

  .FileUploader {
    margin-top: 24px;
  }
}

.UploadDocumentButtonsContainer {
  position: fixed;
  background-color: white;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-right: 40px;
  height: 100px;
  // box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &.isBoxShadowApplied {
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  }
}
