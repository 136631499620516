.OrderPhysicalAppraisalSliderDatePicker {
  // manually change the height of date picker to be consistent with other fields in the form
  height: 58px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    width: 100%;
  }
}
