@import 'styles/variables.scss';

.TwoItemFormRow {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.OtherInsuranceSelect__menu {
  z-index: 2 !important;
}

.OtherInsuranceDatePicker {
  width: 100%;
  .react-datepicker-wrapper,
  .htco-DatePickerInput {
    width: inherit;
  }
}
