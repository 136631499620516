@import 'styles/variables.scss';

.ActionBlock {
  padding: 16px 24px;
  display: flex;
  border-radius: 12px;
  border: 1px solid;
  color: $colorNeutralDark100;
  align-items: center;
  .htco-Icon {
    font-size: 24px;
    margin-right: 8px;
    line-height: 26px;
    align-self: flex-start;
  }
  &.isCenter {
    .htco-Icon {
      align-self: center;
    }
  }
  &.isYellow {
    background-color: $colorSecondaryYellow15;
    border: 1px solid $colorSecondaryYellow30;
  }
}

.ActionBlockDescription {
  font-size: 16px;
  line-height: 26px;
  white-space: break-spaces;
  flex-grow: 1;
}

.ActionBlockActionArea {
  align-self: center;
  justify-self: end;
  margin-left: 7px;
  .htco-Button {
    padding: 0;
    white-space: nowrap;
  }
}
