.PaydownWrapper {
  margin-top: 24px;
}

.MissingLienPayoffWarning {
  margin-bottom: 48px;

  .icon.htco-Icon {
    margin-top: 3px;
    color: #936c00;
  }
}

.PaydownNameField {
  .htco-endAdornment {
    z-index: 3;
  }

  .htco-Tooltip {
    min-width: 500px;

    @media (max-width: 600px) {
      min-width: 300px;
    }
  }

  .htco-MuiTextInputOutlined.htco-withSuffix > input {
    padding-right: 44px;
  }

  .icon.htco-Icon {
    font-size: 24px;
  }
}

.PaydownNameTooltip {
  margin: 0;
}

.PaydownNameTooltipDescription {
  font-weight: bold;
}
