.SummaryLine {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 16px;
  line-height: 26px;

  &.isIndented {
    padding: 8px 16px 8px 40px;
  }
  &.isWithExtraTop {
    padding-top: 16px;
  }
  &.isWithExtraBottom {
    padding-bottom: 16px;
  }
}

.SummaryLineItemLabel {
  line-height: 26px;
  font-weight: 600;
}

.SummaryLineItemLabel {
  line-height: 26px;
}

.SummaryLineItemColon {
  margin-right: 8px;
}

.SummaryLineItemTooltip {
  .icon.htco-Icon {
    line-height: 10px;
    margin-right: 8px;
    font-size: 24px;
  }
}
