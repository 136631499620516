@import 'styles/variables.scss';

.BackgroundCheckFraudGuardTable {
  margin-top: 16px;
  font-size: 14px;

  thead tr {
    color: $colorNeutralDark30;
  }

  td {
    text-transform: capitalize;
  }
}

.BackgroundCheckFGRequestsContainer {
  .TaskDetailHeading {
    margin-bottom: 0;
  }
}

.BackgroundCheckDocumentsList {
  font-size: 14px;
}

.BackgroundCheckRequestInstructions {
  color: $colorNeutralDark30;
}

.BackgroundCheckButtonRow {
  display: flex;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.BackgroundCheckPinIcon {
  color: $colorHometapBlue;
}
