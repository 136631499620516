.iwr-Tooltip,
.fico-Tooltip {
  min-width: 200px;
  max-width: 460px;
}

.ReadonlyUWWrapper {
  display: flex; /* Added for flex-direction to take effect */
  flex-direction: column; /* Overrides the default row layout */

  .ReadonlyUWMultipicker {
    padding-bottom: 0%;
    z-index: 3;
  }

  .UnderwritingDetailsContainer {
    padding-top: 0%;
    row-gap: 24px;
  }
}

/* Underwriting Details container */
.UnderwritingDetails {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px 64px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: #fbfbfa;
  color: #434c5e;
  flex-grow: 1;

  h3 {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Mulish';
  }
}

/* Overview and Deal Terms sections */
.Section {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;

  ul {
    width: 100%;
    margin: 0.5rem 0;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.DealTermsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;
}

.UnderwriterBulletLabel,
.UnderwriterBulletValue {
  line-height: 18px;
  white-space: wrap;
}

.UnderwriterBulletLabel {
  flex: 1;
  margin-right: 8px;
}

.UnderwriterBulletValue {
  color: #152033;
  font-weight: 700;
  text-align: right;
  font-size: 14px;
}

/* Media Queries */
@media (max-width: 1300px) {
  .DealTermsContainer {
    flex-direction: column;
    gap: 0px;
  }

  .UnderwritingDetails {
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}
