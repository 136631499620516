@import 'styles/variables.scss';

.ApplicationReviewAlert {
  padding: 10px 20px;
  margin: 0px;
  background-color: $colorSecondaryYellow15;
  border: 1px solid $colorYellow;
  border-radius: 10px;
  display: flex;
  width: 100%;
  align-items: baseline;

  & + .ApplicationReviewAlert {
    margin-top: 16px;
  }

  &__Icon {
    display: flex;
    position: relative;
    top: 9px;
  }

  &.isAssigned {
    background-color: $colorLightGray2;
    border: 1px solid $colorLightGray4;
  }

  &__Description {
    padding-left: 10px;
    flex-grow: 1;
  }

  &__ActionArea {
    align-self: center;

    button {
      white-space: nowrap;

      &.htco-hasIcon.htco-isLink i.icon-plus {
        top: 0;
        font-size: 16px;
        padding-left: 10px;
      }
    }
  }
}
