.EditDocumentForm {
  overflow-y: scroll;
  padding: 0 40px;
  height: calc(100% - 100px);

  .htco-MuiSelect:has(> .Applicant) {
    margin-top: 30px;
  }
}

.EditDocumentFormTitle {
  padding-top: 40px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 18px;
}

.EditDocumentFormButtonsContainer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-right: 40px;
  height: 100px;
  text-align: end;

  button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.EditDocumentFormLabel {
  display: block;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  margin: 30px 0 8px 0;
}

.EditDocumentFormError {
  padding-top: 20px;
}

.EditDocumentFormName {
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
}
