// Note that this file is identical to its counterpart in staff_portal. Update appropriately.
@import 'styles/variables.scss';

$tabWidth: 30px;

.Configurator {
  overflow-y: hidden;
  background: transparent;
}

.ConfiguratorLeft {
  width: $tabWidth;
}

.ConfiguratorRight {
  background-color: $colorLightGray2;
  padding: 0;
  overflow-y: hidden;
  position: absolute;
  left: $tabWidth;
  width: calc(100% - $tabWidth);
  text-align: center;
}

.ConfiguratorIframe {
  height: 100vh;
  overflow: scroll;
}

.ConfiguratorToggle {
  border-radius: 10px 0px 0px 10px;
  position: fixed;
  top: calc(50% - 37px);
  height: 75px;
  width: $tabWidth;
  outline: none;
  background-color: $colorLightGray2;
  border: 1px solid $colorLightGray4;
  z-index: 100;

  &.ClosedConfiguratorToggle {
    right: 0;
    border-right: 0px;
  }

  img {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-left: -1px;
    vertical-align: middle;
  }
}
