@import 'styles/variables.scss';

.CustomVestingNameHeader {
  margin-top: 16px;
}

.CustomVestingNameInput {
  p {
    margin: 0;
    padding-left: 16px;
    font-size: 14px;
    color: $colorNeutralDark50;
  }

  .htco-MuiTextInputBase {
    margin-bottom: 8px;
  }
}

.PropertyReportTextarea {
  height: 112px;
}
