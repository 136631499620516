@import 'styles/variables.scss';

.SidebarDetail {
  width: 100%;
  padding-bottom: 32px;
}

.SidebarDetailTitle {
  margin-bottom: 24px;
}

.SidebarDetailContent {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 16px;

  .sidebarIcon {
    margin-left: 5px;
  }

  &.isWithSections {
    gap: 40px;
  }
}

.SidebarDetailInlineField {
  display: inline-flex;
}

.SidebarDetailContentLabel {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $colorDarkGray;
}

.icon {
  &.icon-info {
    font-size: 18px;
  }
}
