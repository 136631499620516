@import 'styles/variables.scss';

.TaskDetailContent {
  margin-top: 32px;

  ul {
    line-height: 32px;
  }

  li {
    list-style: disc;
    list-style-position: outside;
    margin-left: 1em;
  }

  &.hideMarginTop {
    margin-top: 0px;
  }
}

.TaskDetailContentTitle {
  color: $colorNeutralDark100;
  font-family: 'Tiempos Headline';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
}

.TaskDetailContentMargin {
  margin-bottom: 24px;
}

.TaskDetailContentIsValidFormItemValid {
  margin-bottom: 24px;
  border-bottom: 1px solid $colorNeutralLight100;
}

.TaskDetailContentFormFields {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 24px;

  &.isInvalid {
    grid-row-gap: 16px;
  }
}

.ReasonsDropdown {
  .htco-DropdownSelector {
    z-index: 2;
  }

  .htco-MuiSelectLabel {
    z-index: 3;
  }
}
