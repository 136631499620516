@import 'styles/variables.scss';

.SidebarDetailSectionTitle {
  font-family: $bodyFont;
  line-height: 23px;
}

.SidebarDetailSectionContent {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 16px;

  .sidebarIcon {
    margin-left: 5px;
  }
}
