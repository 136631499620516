@import 'styles/variables.scss';

.DocumentBatchProgressCancelBatchButton {
  button {
    padding: 1px 2px !important;
  }
}

.DocumentBatchProgressCancelFailedString {
  color: $colorSecondaryRed100;
  font-size: 12px;
  padding-right: 8px;
}

.DocumentBatchProgressInProgressNonHoverContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.DocumentBatchProgressFailureContainer {
  display: flex;
  flex-direction: row;
  color: $colorSecondaryRed100;
  gap: 8px;
  align-items: center;
}
