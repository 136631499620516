@import 'styles/variables.scss';

.SlideInFromRight {
  overflow-y: scroll;
}

.SlideInFromRightSlideIn {
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  color: black;
  height: 100%;
  z-index: 99;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  transform: translateX(100%);
  transition: transform 0.25s;

  @media screen and (min-width: 950px) {
    width: 900px;
  }
  width: 95%;

  &.isOpen {
    transform: translateX(0);
  }
}

.SlideInFromRightCloseIcon {
  position: absolute;
  top: 28px;
  right: 28px;
  padding: 8px;
  transition: 0.25s;
  z-index: 99999;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.SlideInFromRightCloseIconBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 98;
  transition: 0.25s;
  pointer-events: none;
  pointer-events: none;

  &.isOpen {
    opacity: 1;
    z-index: 98;
    pointer-events: all;
  }
}

.SlideInFromRightClickable {
  height: 100%;
  width: 100%;
  z-index: 99;
  pointer-events: all;

  &.isOpen {
    pointer-events: none;
  }
}
