.FlexibleTextarea {
  height: auto;

  & > label {
    line-height: 48px;
  }

  & > textarea {
    padding-bottom: 0;
    line-height: 24px;
  }

  .htco-MuiTextAreaOutline {
    padding: 12px 16px 14px 15px;
    line-height: 24px;
  }

  & > p {
    visibility: hidden;
  }

  &.htco-error > textarea ~ p {
    margin-top: 12px;
    visibility: visible;
  }
}

.FlexibleTextareaHelperText {
  textarea ~ p {
    margin-top: 12px;
    visibility: visible;
  }
}
