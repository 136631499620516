@import './reset.scss';
@import './variables.scss';
@import './mixins.scss';
@import './base.scss';
@import './typography.scss';
@import './toast.scss';
@import './functions.scss';

@tailwind base;
@tailwind utilities;
@tailwind components;
