@import 'styles/variables.scss';

.InvestmentCalculatorHeader {
  border-bottom: 1px solid $colorCardText;

  @include respond('md') {
    h1 {
      font-size: 28px;
      line-height: 39px;
    }
  }
}

.InvestmentCalculatorHeaderBullet {
  padding: 0 10px 0 10px;
  color: $colorPrimaryBlue100;
}

.InvestmentCalculatorHeaderAddress {
  padding-right: 36px;
}

.InvestmentCalculatorStatus {
  color: $colorPrimaryGreen100;

  &.isIneligible {
    color: $colorSecondaryRed100;
  }
}

.InvestmentCalculatorHeaderPreText {
  font-weight: 400;
}

.InvestmentCalculatorErrorCopy {
  display: inline-block;
  padding-left: 10px;

  .htco-Icon {
    color: #fff;
  }
}
