@import 'styles/variables.scss';

.ActionsDropdown {
  .htco-MenuDropdownDots {
    font-size: 22px;
  }
}

.ActionsDropdownDisabled {
  .htco-Tooltip {
    width: max-content;
  }

  .htco-MenuDropdownDots {
    color: $disabledHometapBlue;
    font-size: 22px;
    cursor: auto;
  }
}

.ActionsDropdownAction {
  font-size: 14px;
  padding: 10px;

  &.isDisabled {
    cursor: not-allowed;
    color: $colorNeutralLight100;
    pointer-events: none;
  }
}
