@import 'styles/variables.scss';

.TrackNav {
  display: flex;
  align-items: center;
  height: 55px;

  .htco-MenuDropdown {
    height: 100%;

    &.isActive {
      font-weight: bold;
      border-bottom: 3px solid $colorPrimaryBlue100;
    }

    .htco-MenuDropdownTrigger {
      height: 100%;
    }
  }

  .htco-MenuDropdownContent {
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
}

.TrackNavLink {
  font-family: $bodyFont;
  padding: 16px;
  height: 100%;
  color: $colorNeutralDark75;
  font-size: $fontSizeSm;
  list-style: none;
  font-weight: 700;

  &.isActive {
    font-weight: bold;
    border-bottom: 3px solid $colorPrimaryBlue100;
    color: $colorPrimaryBlue100;
  }

  &.disabled {
    cursor: unset;
    opacity: 0.25;

    &:hover {
      opacity: 0.25;
    }
  }

  &:hover:not(.disabled) {
    background-color: $colorPrimaryBlue5;
    opacity: 1;
  }
}
