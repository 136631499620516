@import 'styles/variables.scss';
.PreviewDocumentBottombar {
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgray;
  background-color: $colorWhite;
  height: 70px;
  min-height: 70px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  div {
    padding: 10px;
  }
  i {
    color: $colorPrimaryBlue100;
  }
  &.isEmbedded {
    border: 1px solid $colorNeutralLight100;
  }
  &.isSmall {
    min-height: 58px;
    height: 58px;
    div {
      padding: 8px;
    }
    i {
      font-size: 1.8em;
    }
  }
  &.isTop {
    border: none;
    min-height: inherit;
    height: inherit;
  }
}

.PreviewDocumentButton {
  margin-right: 0;
  .isDisabled {
    background-color: $buttonDisabled;
  }
  &.isSmall {
    margin-right: 16px;
  }
}
