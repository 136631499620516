@import 'styles/variables.scss';

.PreviewDocument {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &.isFullWindow {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 9999;
  }
  &.isFullScreenEmbedded {
    // fixes image background color and scroll on rotate and zoom
    overflow: auto;
    position: sticky;
    background-color: $colorNeutralLight50;
  }
}

.PreviewDocumentContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 10%;
  height: 90%;
  width: 100%;
  background-color: $colorWhite;
  &.isFullScreenEmbedded {
    text-align: center;
    height: 100%;
    background-color: $colorNeutralLight50;
  }
}
