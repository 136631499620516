@import 'styles/variables.scss';

.PinButton {
  color: $colorPrimaryBlue100;
  margin-right: 20px;
  transition: 0.25s;
  padding: 4px;
  &:hover {
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  }
}
