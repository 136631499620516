@import 'styles/variables.scss';

.Toast {
  position: fixed;
  z-index: 51;
  animation: toast-top-center-fade-in ease-in-out;
  top: -100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
}

.ToastContent {
  padding: 24px 32px;
  min-width: 200px;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &.isSuccess {
    background-color: $colorHometapGreen;
    color: $colorWhite;
  }

  &.isWarning {
    background-color: $colorSecondaryYellow100;
    color: brown; //temporary brown color until pending style overhaul
  }
}

.ToastMessage {
  margin: 0;
  margin-left: 16px;
}

@keyframes toast-top-center-fade-in {
  0% {
    top: -75px;
  }

  20% {
    top: calc(#{$headerHeight} - 32px);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    top: calc(#{$headerHeight} - 32px);
  }
}

.ToastContentDescription {
  max-width: 336px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ToastContentText {
  font-weight: 700;
  color: $colorNeutralDark100;
}

.ToastContentOverflowText {
  text-overflow: ellipsis;
  overflow: hidden;
}
