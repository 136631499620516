@import 'styles/variables.scss';

.TasksControllerContainer {
  padding: 30px;
}

.TaskFilters {
  padding: 30px;
  margin-bottom: 10px;
  border-bottom: solid 1px $colorLightGray3;
}

.TaskFiltersInput {
  margin-right: 10px;
  max-width: 400px;
  margin-bottom: 10px;
  vertical-align: top;
}

.TaskFiltersButton {
  margin: 0px;
}

.TaskFiltersDropdowns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;

  .FilterMultiSelect {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .TaskFiltersButton {
    margin-bottom: 10px;
  }
}

.TaskFiltersDropdownsLabel {
  margin-right: 10px;
}
