@import 'styles/variables.scss';

.ButtonBar {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
  margin-bottom: 10px;
  button.htco-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-weight: 700;
    font-size: 14px;
    &.downloadLoading:hover {
      background-color: white;
      color: $colorPrimaryBlue100;
    }
  }

  & .htco-MenuDropdownTrigger {
    margin-left: 8px;
  }
}

.ButtonBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  transition: 0.25s;
  pointer-events: none;

  &.shouldDisplay {
    transition: 0.25s;
    opacity: 1;
    pointer-events: all;
  }
}

.ButtonBarDropDown {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: $colorNeutralDark100;
  border-radius: 4px;

  & .htco-MenuDropdownTrigger[disabled] {
    border-color: $buttonDisabled;

    & i {
      color: $buttonDisabled;
    }
  }

  button {
    width: 40px;
    height: 40px;
    border: 2px solid $colorPrimaryBlue100;
    border-radius: 4px;
    transition: 0.25s;
    &:hover {
      background-color: $colorPrimaryBlue100;
      i {
        color: $colorWhite;
      }
    }
  }

  i {
    padding-right: 8px;
    color: $colorPrimaryBlue100;
  }
}

.MenuDropdownItemDisabled {
  color: $buttonDisabled;
  & i.icon.htco-Icon.icon-file-pdf {
    color: inherit;
  }
  &[disabled] {
    pointer-events: none;
  }
}

.ButtonBarCreateDocumentSetButton {
  &.htco-Button.htco-isSecondary {
    width: 200px;
  }
}

.ButtonBarLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
