@import 'styles/variables.scss';

.CreateTrackButton.TrackCreation {
  background: $colorSecondaryYellow75;
  color: $colorNeutralDark100;
}

.CoApplicantCount {
  font-size: 1.2rem;
  font-weight: 600;
}

.FormRow {
  display: flex;
  flex-flow: column;
}

.FormRowButton {
  margin: 0;
  height: 48px;
}

.TwoItemFormRow {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.CreateTrackModal {
  .htco-ModalContainer {
    margin: 0 !important;
    padding: 32px;
    border-radius: 12px;
    box-shadow: none;
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  &.loading {
    .htco-LoaderOverlay {
      border-radius: 12px;
      background: rgba(white, 0.4);
      z-index: 2;
    }
  }

  .htco-Accordion {
    &.htco-isOpen div:nth-of-type(2):has(i.htco-DropdownSelectorIndicatorMulti.icon-chevron2-up) {
      /* Apply overflow: inherit only if the select is open and icon is present for drops menus. */
      overflow: inherit !important;
    }
    .htco-AccordionContent {
      margin: 5px 0px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.CreateTrackLink {
  width: max-content;
  color: $colorPrimaryBlue100;

  h3 {
    font-family: $bodyFont;
    font-weight: 600;
    font-size: 20px;
  }

  .ActionsTooltipLinkButtonContainer {
    width: 110px;
  }
}

.CreateTrackModalButtons {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-top: 0;
  }
}

.CreateTrackLinksContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 20px;
  min-height: 10px;
  width: 100%;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 2px 34px 0 rgba(31, 65, 124, 0.1);
}

.CreateTrackLinks {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}
