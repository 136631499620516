@import 'styles/variables.scss';
@import 'styles/functions.scss';

$rightContainerWidthTablet: calc-width(sm, 44);

.TrackTaskNoContent {
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 420px;
}

.TrackTasksContainer {
  width: 100%;
  max-width: 100%;
  position: absolute;
  inset: 0;
  display: flex;
}

.TrackTaskContent {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

%fixedContent {
  min-width: 480px;
  width: 35%;
}

.TrackTaskContentTop {
  width: 100%;
  max-width: 100%;
  display: flex;
  height: calc(100% - 72px);

  %TrackTaskContentLeftOrRight {
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .TrackTaskContentLeft {
    width: 480px;
    flex-grow: 1;
    flex-shrink: 1;
    white-space: break-spaces;
    .htco-NotFoundBlock {
      h1 {
        font-size: 22px;
      }
    }
    .htco-NotFoundBlockImage {
      max-width: 250px;
    }
    @extend %TrackTaskContentLeftOrRight;

    &.isFixed {
      @extend %fixedContent;
      @include respond('md') {
        min-width: calc-width(md, 25);
      }
    }
  }

  .TrackTaskContentMiddle {
    border-left: 1px solid $colorLightGray4;
    @extend %TrackTaskContentLeftOrRight;

    &.isFixed {
      flex-grow: 1;
      border-right: 1px solid $colorLightGray4;
      @extend %fixedContent;
      @include respond('md') {
        width: 25%;
        min-width: 20%;
      }
    }
  }

  .TrackTaskContentRight {
    width: calc-width(lg, 28);
    transition: width 250ms ease;

    &.isMedium {
      width: 30%;
      position: relative;
      overflow: visible;
      @include respond('md') {
        width: 25%;
        min-width: 25%;
      }
    }

    &.isLarge {
      width: calc-width(lg, 48);
    }

    &.isHidden {
      width: 35px;
      min-width: 35px;
    }

    @include respond('sm') {
      width: $rightContainerWidthTablet;

      &.isLarge {
        width: $rightContainerWidthTablet;
      }
    }
    @extend %TrackTaskContentLeftOrRight;
  }
}

.TrackTaskContentBottom {
  position: relative;
  width: 100%;
  height: 72px;
  background-color: $colorWhite;
  box-shadow: 0 2px 10px rgba(32, 53, 104, 0.12);
}

.htco-LoaderOverlay {
  background: rgba(white, 0.8);
}
