@import 'styles/variables.scss';

.TwoItemFormRow {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.TwoItemFormRowItem {
  width: 100%;
}

.MortgageLienSelect__menu {
  z-index: 2 !important;
}

.MortgageLienDatePicker {
  width: 100%;
  .react-datepicker-wrapper,
  .htco-DatePickerInput {
    width: inherit;
  }
  .htco-DatePickerHelperText {
    display: inline-block;
  }
}

.MortgageLienRangeField.htco-error {
  &:has(input:focus) {
    .icon-error {
      opacity: 0;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: block;
    }
  }
  &:has(input:hover:not(:focus)) {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  .icon-error {
    opacity: 1;
  }
}

.MortgageLienDatePicker {
  width: 100%;
  .react-datepicker-wrapper,
  .htco-DatePickerInput {
    width: inherit;
  }
}

.TaskDetailMultipleFormWrapper .MortgageLienReviewContent {
  margin: 0;
}
