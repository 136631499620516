@import 'styles/variables.scss';

.RevisionRequestForm > .RevisionRequestFormTextAreaField {
  .htco-MuiTextareaOutlined {
    color: $colorDarkGray;
    height: 144px;

    textarea {
      line-height: 24px;
    }
  }

  .RevisionRequestForm-label {
    &.htco-MuiTextareaOutlined > label {
      display: none;
    }
  }

  .htco-MuiTextareaOutlined:disabled {
    background-color: $colorLightGray2;
    cursor: not-allowed;
  }
}

.SliderFormTextInputField {
  & > div label {
    display: none;
  }
}
