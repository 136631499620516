@import 'styles/variables.scss';

.htco-MuiSelect.htco-isMultiple {
  margin-top: 0px;
}

.SelectionYesNoSetFormItem {
  margin-top: 0px;
}

.HasAdverseEventsCheckbox {
  margin: 16px 0;
}

.AdverseEventFormRowExtraPadding {
  padding-bottom: 24px;
}
