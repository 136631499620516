.RedFlagCheckDataTableContainer {
  .htco-DataTable .rdt_Table {
    min-height: auto;
  }
}

.RedFlagCheckTableDropdownDisplay {
  max-width: 800px;
  margin: 0;
}
