@import 'styles/variables.scss';

.MenuDropdown {
  background-color: $colorNeutralLight50;
  border-radius: 4px;
  width: 100%;
  position: relative;

  &.isOpen {
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: $colorPrimaryBlue100;
      position: absolute;
    }
  }

  .htco-MenuDropdownTrigger {
    width: 100%;
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 4px;
    height: 38px;
    color: $colorPrimaryBlue100;

    .htco-MenuDropdownChevron {
      margin-left: 0;
    }
    &.htco-isDisabled {
      color: $colorNeutralDark50;
    }
  }

  .htco-MenuDropdownContent {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    border: none;
    padding-top: 0;
    transform: translate3d(0px, 40px, 0px) !important;
    box-shadow: 0px 4px 20px rgba(32, 53, 104, 0.14);
  }
}
