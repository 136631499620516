@import 'styles/variables.scss';

.DocumentActions {
  display: flex;
  align-items: center;
}

.DocumentActionsMenuContainer {
  min-width: 0;
  .htco-MenuDropdownContent {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    i {
      padding-right: 5px;
      color: $colorPrimaryBlue100;
    }
  }
}

.DocumentActionsUploadNewVersionDisabled {
  color: gray;
  i::before {
    color: gray;
  }
}

.DocumentActionsMenuBackdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  opacity: 0;
}

.DocumentActionsMenuContainerOpen {
  z-index: 3;
}
