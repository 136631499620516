@import 'styles/variables.scss';

.TracksControllerContainer {
  padding: 30px;
}

.TrackFilters {
  padding: 30px;
  margin-bottom: 10px;
  border-bottom: solid 1px $colorLightGray3;
}

.DisabledLink {
  color: $colorNeutralDark50;
  cursor: not-allowed;
}

.TrackFiltersDropdowns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;

  .FilterMultiSelect {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .TrackFiltersButton {
    margin-bottom: 10px;
  }
}

.TrackFiltersDropdownsLabel {
  margin-right: 10px;
}

.htco-Button.htco-isTextButton.ClaimButton {
  margin: 0;
  text-transform: none;
}
