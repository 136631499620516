@import 'styles/variables.scss';

.ApplicationReviewSection {
  border-bottom: 1px solid $colorNeutralLight100;
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
  min-height: 120px;
  padding: 0px 40px 0px 32px;
  width: 100%;

  .MultiPickerContainer {
    padding-top: 42px;
    padding-bottom: 42px;
    align-self: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    flex: 1 1 0;
  }

  .FormDetailsContainer {
    display: flex;
    flex-direction: column;
    flex: 2 1 0;
    gap: 24px;
    height: fit-content;
    overflow: visible;
    padding: 42px 0px 72px 32px;

    & > .htco-isMultiple {
      margin-top: 0;

      & div[class$='multiValue'] {
        background-color: $colorNeutralLight50;
      }
    }

    .detailItemContainer {
      display: inherit;
      flex-direction: inherit;
      flex: inherit;
      gap: inherit;
      height: inherit;
      padding: 0px 0px 16px 0px;
    }

    .detailItemContainer:last-child {
      padding-bottom: 0px;
    }

    //The default styling for the `SelectionSet` htco-component breaks the gap spacing.
    .SelectorElement {
      width: 100%;
      & > .htco-Label {
        margin-top: 0px;
      }
    }
    //Set selector does not have a default disabled style. This implements one.
    .disabledSelectorElement {
      & > .htco-SelectionSetWrapper {
        & > .htco-SelectionSetItem {
          background-color: $colorNeutralLight50;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    @mixin full-width-date-picker {
      width: 100%;
      .react-datepicker-wrapper,
      .react-datepicker__input-container,
      .react-datepicker__input-container input {
        display: block;
        width: 100%;
      }
    }
    //Styling for when two forms fields need to exist on the same row
    .TwoItemFormRow {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
      //Forces the DatePicker to take up a 50% of space on two item form row
      .FullWidthDatePicker {
        @include full-width-date-picker;
      }
    }

    //Styling for a single date field per row
    .FullWidthDatePicker {
      @include full-width-date-picker;
    }
  }

  //unset the default line height which cuts off text with a larger font size
  .htco-MuiTextInputOutlined.htco-withMask > input:not([value='']) + label > label {
    line-height: unset;
    //avoid label slipping below field outline
    margin-top: -4px;
  }
}
