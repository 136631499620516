#hoa-section {
  .ApplicationReviewActionBar {
    margin-top: 24px;
  }
  .HOADetailsTable {
    display: flex;

    .HOADetailsTableColumn {
      flex-grow: 1;
      width: 50%;
      padding: 0 12px;
    }
  }
  .HOADetailsTableField {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    a {
      font-weight: bold;
    }
    b,
    span {
      display: block;
    }
  }
}
