@import 'styles/variables.scss';

.HomeValuationsReggoraContainer {
  border: 1px solid $colorNeutralLight100;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  &.htco-DataTable {
    margin-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid $colorNeutralLight100;

    &.rdt_TableRow {
      border: 1px solid $colorNeutralLight100;
      border-bottom: 1px solid $colorNeutralLight100;
    }
  }
  .rdt_TableHead {
    height: 0;
  }
  .rdt_TableRow:not(:last-of-type) {
    border-bottom: 1px solid $colorNeutralLight100;
  }
}

.HomeValuationStatusWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.HomeValuationsReggoraTitle {
  padding: 8px 16px;
  font-size: 18px;
  line-height: 30px;
  color: $colorNeutralDark100;
  font-weight: 700;
  font-family: Mulish;
  border-bottom: 1px solid $colorNeutralLight100;
}
.HomeValuationReggoraStatus {
  justify-content: end;
}

.HomeValuationsReggoraTableHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.HomeValuationReggoraHeader {
  color: $colorNeutralDark100;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  height: auto;
  border-bottom-color: $colorNeutralLight100;
}

.HomeValuationTableText {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: $colorNeutralDark75;
}

.PropertyConditionContainer {
  .htco-Label {
    margin: 0;

    .htco-LabelText {
      font-weight: bold;
      color: $colorBlack;
      font-size: 16px;
    }
  }
}

.NotesAreaContainer {
  .htco-Label {
    margin-bottom: 4px;
    margin-top: 0;

    .htco-LabelText {
      font-weight: bold;
      color: $colorBlack;
      font-size: 16px;
    }
  }

  .htco-TextInput {
    color: $colorDarkGray;
    height: 120px;
    line-height: 24px;
    padding-top: 10px;
    resize: none;
  }
}

.ReggoraDocumentsLink {
  overflow: hidden !important;
  color: $colorPrimaryBlue100;
  cursor: pointer;
}
