@import 'styles/variables.scss';

.InvestmentCalcInputsContainer {
  padding-bottom: 75px;

  .InvestmentCalcInputsSection {
    margin-right: 100px;

    @include respond('md') {
      margin-right: unset;
    }
  }
}

.InvestmentCalcMaxPotentialInvestment {
  padding-top: 35px;
}

.InvestmentCalcSalesforceLink {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: 700;

  .htco-Icon {
    padding-top: 4px;
    margin-left: 8px;
  }
}

.InvestmentCalcFooter {
  position: fixed;
  bottom: 0;
  z-index: 50;
}

.InvestmentCalcFooterSaveIconCheck {
  margin-left: 0 !important;

  .htco-Icon {
    margin-top: 22px;
    margin-right: 8px;
    color: $colorPrimaryBlue100;
  }

  .IconCheckText {
    margin-top: 18px;
    font-style: italic;
  }
}

.InvestmentCalcFooterButtons {
  margin-right: 0 !important;
}
