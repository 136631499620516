@import 'styles/variables.scss';

.GuideCalculatorButton {
  margin-right: 10px;
  color: $colorNeutralDark100;
}

.GuideCalculatorCloseButtonWrapper {
  display: flex;
}

.GuideCalculatorCloseButton {
  margin-left: auto;
  margin-right: 60px;
  margin-top: 40px;
  color: $colorNeutralDark100;
}
