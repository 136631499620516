@import 'styles/variables.scss';

.DetailInformationBlock {
  padding: 16px 24px;
  display: flex;
  border-radius: 12px;
  border: 1px solid;
  color: $colorNeutralDark100;
  &.isExpiringOrInvalid {
    border-color: $colorPrimaryBlue100;
    background-color: $colorPrimaryBlue5;
  }
  &.isCompleted {
    border-color: $colorNeutralDark100;
    background-color: $colorNeutralLight50;
    align-items: center;
  }
  &.isWithIcon > span {
    margin-left: 8px;
  }
  & > span {
    font-size: 16px;
    line-height: 26px;
    white-space: break-spaces;
  }
  &.alert {
    border-color: $colorSecondaryRed100;
    background-color: $colorSecondaryRed15;
    i {
      color: $colorSecondaryRed100;
    }
  }
  &.error {
    border-color: $colorSecondaryRed100;
    background-color: $colorSecondaryRed15;
    i {
      color: $colorSecondaryRed100;
    }
  }
  &.warning {
    border-color: $colorYellow;
    background-color: $colorSecondaryYellow15;
  }
}

.DetailInformationBlockIcon {
  &.isCompleted {
    margin-top: 0;
  }
  &.icon.htco-Icon {
    font-size: 24px;
  }
  &.isTop {
    margin-top: 7px;
  }
  &.isCenter {
    align-self: center;
  }
}
