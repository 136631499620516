@import 'styles/variables.scss';

$height: 32px;
$tooltipZIndex: 50;

.ActionTooltip {
  position: relative;

  &.hasActions {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ActionsTooltipContainer {
  height: auto;
  position: absolute;
  bottom: 2em;
  z-index: $tooltipZIndex;
}

.ActionsTooltipButtonsContainer {
  height: 100%;
  position: relative;
  z-index: $tooltipZIndex + 1;
  border-radius: 4px;
  overflow: hidden;
  background-color: $colorDarkNavy;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: stretch;

  .ActionsTooltipButtonContainer:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -1px;
    width: 1px;
    height: 24px;
    background-color: $colorLightGray1;
    z-index: 1;
  }
}

.ActionsTooltipVerticalLine {
  width: 1px;
  height: 24px;
  background-color: $colorLightGray1;
}

.ActionsTooltipButtonContainer {
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $colorNeutralDark75;
  }

  &:active {
    background-color: $colorNeutralDark50;
  }
}

.ActionsTooltipButtonText {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $colorLightGray1;
  margin-left: 11px;
}

.ActionsTooltipLinkButtonContainer {
  width: 108px;
  padding-right: 12px;
}

.ActionsTooltipCopyButtonContainer {
  padding-right: 8px;
}

.ActionsTooltipEditButtonContainer {
  padding-right: 12px;
}

.ActionTooltipIconColor {
  color: $colorNeutralLight30;
}
