@import 'styles/variables.scss';

.TrackDetailsContainer {
  min-height: calc(100vh - #{$headerHeight});
  display: flex;
  justify-content: center;
  width: 100%;
}

.TrackDetails {
  background-color: $colorWhite;
  width: 100%;
  display: flex;
  justify-content: center;
}

.TrackDetailsContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.TrackDetailsPadding {
  padding-left: 40px;
  padding-right: 40px;
}

.TrackDetailsAddress {
  border-bottom: solid 1px $colorNeutralLight100;
  overflow: hidden;
}

.TrackDetailsTrackNav {
  border-bottom: solid 1px $colorNeutralLight100;
  overflow: visible;
}

.TrackDetailsContent {
  position: relative;
  height: 100%;

  &.isTrackDisabled {
    opacity: 0.5;
    // globally disable interactive elements.
    pointer-events: none;

    .TrackDetailsPartiallyDisabled {
      pointer-events: all;
      // Note this does not disallow all functionality from being nonoperable and is assumed that
      // this style only disables the UI elements at the surface level; if there is an element that
      // creates some sort of side effect (e.g., click event) that is important to _not_ happen when
      // a track is ClosedLost that should be dealt with at the component level.
      input,
      button,
      a,
      label,
      [role='button'],
      [role='checkbox'] {
        pointer-events: none;
      }
    }
  }
}

.TrackProductDetails {
  overflow: visible;
}

.TrackDetailsListHorizontal li {
  display: inline-block;
}

.TrackDetailsListHorizontal li:before {
  content: '\00a0\2022\00a0\00a0';
}

.TrackDetailsListHorizontal li:first-child:before {
  content: '';
}
