@import 'styles/variables.scss';

.TaskDataControllerContainer {
  padding: 32px 32px 0 32px;
  max-width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-left: 1px solid $colorLightGray4;
}

.TaskDataControllerContainerCenterAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TaskDataControllerTitle {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  color: $colorDarkNavy;
  margin-bottom: 32px;
}
