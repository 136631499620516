@import 'styles/variables.scss';

.AVStatus {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  margin: 24px;
  gap: 8px;
  width: 100%;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  background-color: $colorSecondaryRed15;
  color: $colorSecondaryRed100;
  &.isEncrypted {
    background-color: $colorSecondaryYellow15;
    color: #b88c13;
  }
}

.AVStatusContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
