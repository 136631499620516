.TaskDocumentsDrawerWrapper {
  position: relative;
  width: auto !important;
  .htco-SlideSideChildren {
    width: inherit !important;
  }
}

.TaskDocumentsDrawerButton {
  z-index: 100;
  left: -14px;
  transition: padding 0.3s ease;
  .TaskDrawerControlButtonIcon {
    transform: rotate(270deg) translateY(-2px);
  }
  &.isOpen {
    .TaskDrawerControlButtonIcon {
      transform: rotate(90deg);
    }
  }
}
