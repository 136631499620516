@import 'styles/variables.scss';

.FormListViewData {
  margin-bottom: 30px;
  border-bottom: 1px solid $colorNeutralLight100;
}

.FormListViewDataRow {
  padding: 16px 0 16px 0;
}

.FormListViewDataWrapper {
  padding: 12px 0 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:empty {
    display: none;
  }
}

.FormListViewDataValueLinkWrapper {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: right;
  margin-left: 16px;
}

.FormListViewDataLink {
  padding-left: 16px;
  font-weight: 700;

  i {
    margin-left: 8px;
  }
}

.FormListViewDataValue {
  font-weight: 700;
}

.FormListViewDataLabelTooltipIcon {
  color: $colorHometapBlue;
}
