@import 'styles/variables.scss';

.DocumentFilters {
  padding-bottom: 30px;
  margin-left: 8px;
  border-bottom: solid 1px $colorLightGray3;
  margin-top: 15px;
}

.DocumentFiltersDropdowns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  .FilterMultiSelect {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
  }

  .DocumentFiltersButton {
    margin-bottom: 10px;
  }
}

.DocumentFiltersDropdownsLabel {
  margin-right: 10px;
}

.htco-Button.htco-isTextButton.ClaimButton {
  margin: 0;
  text-transform: none;
}
