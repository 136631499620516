@import 'styles/variables.scss';

.RiskBandDisplayContainer {
  &.isFake {
    background-color: $colorSecondaryYellow30;
    padding: 5px;
    border-radius: 3px;
  }
}

.RiskBandDisplayContent {
  display: inline-flex;
  align-items: center;
}

.RiskBandDisplayErrorIcon {
  margin-left: 5px;

  &:before {
    color: $colorSecondaryRed75;
  }
}
