@import 'styles/variables.scss';

.SearchBoxInput {
  margin-right: 10px;
  max-width: 400px;
  margin-bottom: 10px;
  vertical-align: top;
}

.SearchBoxButton {
  margin: 0px;
}
