@import 'styles/variables.scss';

.DataListItemValue {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: $colorDarkNavy;
}

.DataListItemMessage {
  margin-top: 8px;
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: $colorNeutralDark50;

  list-style-type: none;

  &:before {
    content: '-';
  }
}
