@import 'styles/variables.scss';

.FundingControllerPage {
  padding: 40px;
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.FundingControllerPaper {
  border-radius: 8px;
  margin-bottom: 60px;
}

.FilterMultiSelect {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FundingControllerOfferContainer {
  font-size: 20px;
  font-weight: 500;
}

.FundingControllerOfferAmountTag {
  margin-right: 4px;
  color: $colorNeutralDark50;
}

.FundingTableContainer {
  &.htco-DataTable {
    margin-bottom: 100px;
    font-size: 16px;
    font-weight: 400;
    :first-child {
      overflow: visible;
    }

    .rdt_TableRow {
      transition: 0.25s;
    }
  }
  .rdt_TableCol {
    padding-bottom: 12px;
  }
}

.FundingTable {
  & > [role='heading'] {
    & > div {
      font-weight: 700;
    }
  }
}

.FundingControllerSaveLoader {
  transform: translateY(-5px);
}

.FundingControllerSaveFundsSubmitButton {
  width: 160px;
}

.FundingControllerDemoButton {
  position: relative !important;
  transform: unset !important;
  bottom: unset !important;
  left: unset !important;
}

.FundingControllerActionButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $colorWhite;
  width: 100%;
  box-shadow: 0 2px 10px rgba(32, 53, 104, 0.12);
  gap: 12px;
  padding-right: 12px;
}

.FundingControllerFillAllButton {
  background-color: $colorAltGreen;
  border-color: $colorAltGreen;
  width: 160px;

  &:hover {
    border-color: $colorPrimaryGreen100;
    background-color: $colorPrimaryGreen100;
  }
}
