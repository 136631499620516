@import 'styles/variables.scss';

.SSNShowHideContainer {
  display: flex;
}

.SSNShowHideValue {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: $colorDarkNavy;
}

.SSNShowHideButton {
  width: 18px;
  height: 18px;
  margin-left: 16px;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  align-items: center;

  &.isDisabled {
    cursor: default;
  }
}
