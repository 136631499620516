@import 'styles/variables.scss';

.SummaryLien {
  padding: 0 16px;
}

.SummaryLienLabel {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
}

.SummaryLienLabelNormal {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
}

.htco-DropdownSelector > div[class$='-menu'] {
  z-index: 2;
}

.FormRow {
  display: flex;
  flex-flow: column;
}

.FormItem {
  width: 100%;
}

.htco-SelectionSet .htco-Label {
  margin-top: 0;
}
.ForbearanceDetailsBlock {
  margin-top: 25px;
}

.DemoLienWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: center;
}

.DemoLienSelector {
  border: 3px solid $colorYellow;
  border-radius: 8px;
}
