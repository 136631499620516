@import '../../node_modules/@hometap/htco-components/src/scss/_breakpoints.scss';
@import '../../node_modules/@hometap/htco-components/src/scss/_variables.scss';

// Layout Sizes
$headerHeight: 55px;

// Font Sizes
$fontSizeBody: 16px;
$fontSizeSm: 14px;
$fontSizeLg: 18px;

$bodyFont: 'Mulish', sans-serif;
$colorBodyText: #182133;
$colorStatusCircleOpen: #366ced66;
$colorDarkTeal100: #0e7e97;
$disabledHometapBlue: rgba($colorHometapBlue, 0.5);
