@import 'styles/variables.scss';

.FinalApproveButton {
  margin: 0;
  width: 139px;
}

.UnderwritingModal {
  .htco-ModalContainer {
    margin: auto !important;
    border-radius: 12px;
    box-shadow: none;
    padding: 72px 88px 64px;
  }
}

.UnderwritingModalDetails {
  display: flex;
  flex-direction: column;
}

.UnderwritingModalTitle {
  line-height: 50px;
}

.UnderwritingModalSubTitle {
  line-height: 30px;
  font-size: 18px;
  color: $colorNeutralDark75;
  padding: 8px 0 32px;
}

.UnderwritingModalButton {
  width: 100%;
  margin-top: 48px;
}

.UnderwritingModalErrors {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 360px;
  overflow-y: auto;
}

.UnderwritingModalError {
  padding: 16px 24px;
  background: $colorSecondaryRed15;
  border: 1px solid $colorSecondaryRed100;
  border-radius: 6px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;

  i.icon.htco-Icon {
    font-size: 20px;
    line-height: 26px;
  }
}

.UnderwritingModalErrorIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: $colorSecondaryRed100;
}
