@import 'styles/variables.scss';

.FormFieldsGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 24px;
}

.AddAnotherField {
  margin-bottom: 25px;
}
