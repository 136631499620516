@import 'styles/variables.scss';

.reggoraStatusLabel {
  white-space: nowrap;
  overflow: hidden;
}

.ReggoraStatusText {
  line-height: 28px;
}
