@import 'styles/variables.scss';

.SavedDisplay {
  display: flex;
  align-items: center;
  color: $colorNeutralDark100;

  .SavedDisplayIconWrapper {
    display: flex;
    justify-content: center;
    width: 35px;
    margin-right: 12px;
  }

  .htco-Icon.icon-check {
    color: $colorPrimaryBlue100;
  }
}
