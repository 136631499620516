@import 'styles/variables.scss';

.PreviewDocumentTopbar {
  height: 70px;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid $colorNeutralLight100;
  background-color: $colorWhite;
  align-items: center;
  z-index: 9;
  &.isEmbedded {
    border-radius: 6px 6px 0 0;
  }
  &.isSmall {
    height: 58px;
    min-height: 58px;
  }
  &.isFullScreenEmbedded {
    position: sticky;
    top: 0;
    left: 0;
    border-radius: unset;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-right: 16px;
    padding-left: 12px;
  }
}

.PreviewDocumentTopbarActions {
  display: flex;
  align-items: center;
  gap: 8px;
  &.isSmall {
    i {
      font-size: 1.8em;
    }
  }
}

.PreviewDocumentFileName {
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PreviewDocumentTopbarLeft {
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  min-width: 0;

  .closeButton {
    padding: 5px;
    cursor: pointer;
    i {
      color: $colorPrimaryBlue100;
      font-weight: 100;
    }
  }

  .backButton {
    color: $colorPrimaryBlue100;
    cursor: pointer;
    i {
      color: inherit;
      font-weight: 100;
    }
  }
}

.PreviewDocumentKindTooltip {
  padding-left: 5px;
  align-self: start;
}

.PreviewDocumentPageNumbers {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.PreviewDocumentArrows {
  margin-right: 12px;
  flex-shrink: 0;
  &.isFullWindow {
    transform: translateY(2px);
    span {
      margin: 5px;
      // left and right buttons top nav bar
      i {
        color: $colorPrimaryBlue100;
      }
    }
  }
  &.isEmbedded {
    display: flex;
    flex-direction: row;
    div {
      // left and right buttons top nav bar
      i {
        color: $colorPrimaryBlue100;
      }
    }
  }
}

.PreviewDocumentArrow {
  cursor: pointer;
}
