@import 'styles/variables.scss';

.FundItemContainer {
  display: flex;
  flex-direction: column;
}

.FundItemNumberContainer {
  font-family: $titleFont;
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.FundItemLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #434c5e;
}

.FundItemAmount {
  color: $colorNeutralDark100;
}

.FundItemAmountBlue {
  color: $colorPrimaryBlue100;
}

.FundItemAmountRed {
  color: $colorSecondaryRed100;
}

.FundItemAmountGreen {
  color: $colorMediumGreen100;
}

.FundItemPercent {
  color: $colorNeutralDark50;
}

.FundItemAmountBlack {
  color: black;
}

.FundIconTooltipIcon::before {
  font-size: 0.75em;
  position: absolute;
  top: 0;
  font-weight: bold;
  color: $colorNeutralDark30;
}
