@import 'styles/variables.scss';

$marginDefault: 16px;

@mixin textDefaultStyles {
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

@mixin listItemStyles {
  @include textDefaultStyles();
  line-height: 23px;
  color: $colorNeutralDark50;
}

.ApplicationChangesTooltipCustom {
  margin-top: 3px;
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(54, 108, 237, 0.3);
  border-width: 0;

  & > div:first-child {
    background-color: red;
    overflow: visible;
  }

  & > div:first-child:after {
    left: 0 !important;
  }
}

.ApplicationChangesTooltipBodyLoading {
  width: 332px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ApplicationChangesTooltipError {
  .htco-NotFoundBlockImage {
    max-width: 0px;
  }
}

.ApplicationChangesTooltipTitle {
  border: 1px solid $colorNeutralLight100;
  border-radius: 4px 4px 0 0;
  background-color: $colorPrimaryBlue100;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: $colorWhite;
  padding: 11px 0 4px 16px;
}

.ApplicationChangesTooltipContent {
  overflow-y: scroll;
  padding: $marginDefault 18px;

  &.heightL {
    max-height: 60vh;
  }

  &.heightM {
    max-height: 50vh;
  }

  &.heightS {
    max-height: 40vh;
  }

  & > p:first-child {
    @include textDefaultStyles();
    line-height: 18px;
    color: $colorNeutralDark100;
    margin: 0;
  }

  & > p:nth-child(2) {
    @include textDefaultStyles();
    line-height: 22px;
    color: $colorNeutralDark100;
    margin: $marginDefault 0 0 0;
  }

  & > h5 {
    @include textDefaultStyles();
    font-size: 16px;
    line-height: 26px;
    color: $colorBlack;
    margin-top: $marginDefault;
  }
}

.ApplicationChangesChangeSetContainer {
  margin-top: 32px;

  & > p {
    margin: 0;
  }
}

.ApplicationChangesTextUppercase {
  margin-top: $marginDefault;
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $colorNeutralDark100;
}

.ApplicationChangesObjectContainer {
  margin-top: 6px;
}

.ApplicationChangesChangedInstanceName {
  @include listItemStyles();
  margin: 0;
}

.ApplicationChangesNoNotableChanges {
  @include listItemStyles();
  margin: 0;
}

.ApplicationChangesAddedOrRemovedObjectField {
  @include listItemStyles();
  list-style-type: disc;
  margin-left: 24px;

  & > ul {
    list-style-type: disc;
    margin-left: 20px;
  }
}

.ApplicationChangesEdited {
  @include listItemStyles();
  list-style-type: disc;
  margin-left: 24px;

  .ApplicationChangesChangedListItem {
    list-style-type: disc;
    margin-left: 20px;

    & > ul {
      margin-left: $marginDefault;
    }
  }
}
