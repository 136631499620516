@import 'styles/variables.scss';

.ExpiredGovernmentIdAlert {
  padding: 10px 20px;
  margin: 10px 0px;
  background-color: $colorSecondaryYellow15;
  border: 1px solid $colorYellow;
  border-radius: 10px;
  display: flex;

  .alertListIcon {
    padding-right: 10px;
  }
}
