@import 'styles/variables.scss';

.PreviewDocumentTile {
  margin: 16px;
  height: 280px;
  background-color: $colorNeutralLight50;
  border-radius: 6px;
  border: 1px solid $colorNeutralLight100;
  position: relative;
}

.PreviewDocumentFileWrapper {
  padding: 10px;
  height: 100%;
  width: 100%;
}

.PreviewDocumentTileDescription {
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 75px;
  bottom: 0;
  right: 0;
  background: white;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 12px 26px;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-wrap: wrap;
}

.PreviewDocumentTileDescriptionTooltip {
  padding-left: 5px;
}

%tileStyles {
  font-family: 'Mulish';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.PreviewDocumentTileFilename {
  font-weight: 700;
  flex: 50%;
  margin-right: 6px;
  padding: 2px 0;
  @extend %tileStyles;
}

.PreviewDocumentTileCreatedAt {
  font-weight: 400;
  flex: 100%;
  padding: 2px 0;
  @extend %tileStyles;
}

.PreviewDocumentTileAntiVirusStatus {
  margin: 2px 0;
}
