@import 'styles/variables.scss';

.MultiPickerContainer {
  padding: 0 32px 0 32px;
  width: 100%;
}

.MultiPickerHeader {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .MultiPickerTitle {
    margin-right: 16px;
  }

  .MultiPickerEditButton {
    color: $colorPrimaryBlue100;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }
}

.MultiPickerDetails {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 24px;
}

.MultiPickerHistoryLink {
  color: $colorNeutralDark50;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 24px;

  & > a {
    color: inherit;
  }

  &.sectionDetails {
    margin-bottom: 8px;
  }
}

.MultiPickerBottomLink {
  margin-left: 16px;
  margin-bottom: 24px;
}

.SelectorOptionsContainer {
  margin-bottom: 40px;

  &.isWithActionBar {
    margin-bottom: 32px;
  }
  &.bottomLink {
    margin-bottom: 16px;
  }
}

.SelectorOption {
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.isDisabled:hover {
    background-color: unset;
    cursor: default;
  }

  &:has(.SelectorOptionDoc) {
    padding-right: 16px;
    a:hover {
      opacity: 1;
    }
  }

  &:hover {
    background-color: $colorPrimaryBlue5;
    border-radius: 6px;
  }

  &[data-selected='true'] {
    background-color: $colorPrimaryBlue15;
    border-radius: 6px;
  }

  &.hasError {
    padding-left: 10px;
  }

  .SelectorOptionLabel {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    flex-basis: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.isDisabled {
      color: $colorNeutralDark50;
      margin-bottom: 4px;
    }
  }

  .SelectorOptionDetails {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .SelectorOptionDetailsWrapper {
    display: inline-block;
  }

  .SelectorOptionDetailsValue {
    font-weight: 700;
  }

  .SelectorOptionDetailsAdditionalValue {
    font-weight: 700;
    color: $colorSecondaryRed100;
  }

  .SelectorOptionDetailsDivider {
    padding: 0 8px;
    font-size: 16px;
  }

  .SelectorOptionLabelWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 8px;
    overflow: hidden;
    flex-grow: 1;

    &.isPending {
      align-items: normal;
    }
  }

  .SelectorOptionLabelBadge {
    height: auto;
    position: inherit;
  }

  .SelectorOptionDoc {
    background: $colorNeutralLight30;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &:hover {
      opacity: 1;
      background: $colorPrimaryBlue5;
    }
    &:active {
      background-color: $colorPrimaryBlue50;
    }
  }
}

.MultiPickerItem-enter {
  opacity: 0;
  transform: translateY(-30px);
}

.MultiPickerItem-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.MultiPickerItem-exit {
  opacity: 1;
}

.MultiPickerItem-exit-active {
  opacity: 0;
  transform: translateY(-30px);
  transition:
    opacity 300ms,
    transform 300ms;
}

.SelectorOptionAlertIconWrapper {
  margin-left: -8px;
  margin-right: 12.5px;
  text-align: center;
  height: 24px;
  width: 24px;
  .SelectorOptionAlertIcon {
    height: 20px;
    width: 20px;
  }
}
