.MergeDocumentsModal {
  .htco-ModalContainer {
    top: 50%;
    margin: 0 !important;
    transform: translateY(-50%);
    padding: 32px;
    border-radius: 12px;
    box-shadow: none;
  }

  & label.htco-Checkbox {
    margin-top: 30px;
  }

  &.isLoading {
    .htco-LoaderOverlay {
      border-radius: 12px;
      background: rgba(white, 0.4);
    }
  }
}

.MergeDocumentsModalField {
  margin-top: 30px;
}

.MergeDocumentsModalButtons {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-top: 0;
  }
}

.MergeDocumentsModalColumnContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    margin-bottom: 8px;
  }
}
