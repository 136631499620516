@import 'styles/variables.scss';

.SliderContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.CreateDocumentSetBatchSliderCloseButton {
  position: absolute;
  top: 28px;
  right: 28px;
  padding: 8px;
  transition: 0.25s;
  z-index: 99999;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.CreateDocumentSetBatchSlider {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  flex-wrap: nowrap;
  height: calc(100% - 100px);
}

.CreateDocumentSetBatchSliderFooter {
  border-top: 1px solid $colorNeutralLight100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 900px;
  padding: 10px 40px;
  height: 100px;
}

.CreateDocumentSetBatchSliderFooterButton {
  display: flex;
  flex-direction: row;
}

.BadFriendlyIdWarningContainer {
  border: 1px solid $colorSecondaryRed100;
  border-radius: 12px;
  background-color: $colorSecondaryRed15;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > .BadFriendlyIdWarningIcon {
    margin-right: 12px;
  }
}
