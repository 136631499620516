.SelectorElement {
  width: 100%;
  & > .htco-Label {
    line-height: 26px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.htco {
  &-SelectionSetItem {
    height: 48px;
    min-height: 48px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
      justify-content: center;
    }
  }

  &-SelectionSetWrapper {
    margin-top: 0px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
  }
}

.ProofOfRentalFrequencySelection .htco-SelectionSetWrapper {
  flex-wrap: nowrap;
}

.RentalDetails {
  padding-top: 20px;
}
