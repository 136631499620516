.UploadDocumentProcessingTypeContainer {
  margin-top: 32px;
  margin-bottom: 32px;
}

.UploadDocumentProcessingLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.UploadDocumentProcessingDocumentType {
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
}

.UploadDocumentProcessingAdditionalField {
  margin-top: 16px;
}
