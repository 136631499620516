@import 'styles/variables.scss';

.RentalEligibilitySidebarContainer {
  padding: 24px 24px 0;
  border-radius: 6px;
  border: 1px solid $colorNeutralLight100;

  .RentalEligibilityRow {
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    flex-wrap: wrap;

    &.LienKind {
      padding-left: 21px;
    }
  }

  .htco-Accordion:not(.htco-isOpen) {
    .htco-AccordionHeader {
      border-bottom: none;
    }
  }

  .htco-AccordionHeader {
    padding: 0 0 24px;
  }

  .htco-AccordionContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.RentalEligibilityAccordionTitle {
  font-size: 16px;
  font-weight: 400;
  color: #58687d;
}

.RentalEligibilitySectionTitle {
  text-transform: uppercase;
  font-size: 12px;
  color: $colorNeutralDark50;
}

.RentalEligibilityFooter {
  border-top: 1px solid $colorNeutralLight100;
  margin-top: 24px;
  padding-top: 6px;
}

.RentalEligibilityDSCR {
  color: $colorNeutralDark100;
}

.RentalEligibilityLabel {
  margin-right: 24px;
}
