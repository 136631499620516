@import 'styles/variables.scss';

.TaskDocuments {
  padding: 32px 24px 0 32px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-left: 1px solid $colorLightGray4;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.isPreviewDocumentOpen {
    padding: 0;
  }
  &.isWithDrawer {
    position: relative;
    border-left: none;
  }
}

.TaskDocumentsHeader {
  margin-bottom: 32px;
}

.PreviewDocumentInner {
  width: 100%;
  height: 100%;
}

.PreviewDocumentBack {
  margin-bottom: 32px;
  color: $colorPrimaryBlue100;
  cursor: pointer;
  i {
    color: $colorPrimaryBlue100;
  }
}
