.ReadOnlyFieldGroupContainer {
  display: flex;
  flex-direction: row;
}

.ReadOnlyFieldGroupColumn {
  flex: 1;
  text-align: left;

  .htco-Button.htco-hasIcon.htco-isLink {
    padding-left: 0;
  }
}
