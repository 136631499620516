@import 'styles/variables.scss';

.MinMaxCurrencyContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.MinMaxCurrencyAmount,
.MinMaxCurrencyRange {
  font-family: $bodyFont;
  font-style: normal;
  color: $colorDarkNavy;
}

.MinMaxCurrencyAmount {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.MinMaxCurrencyRange {
  font-weight: 400;
  font-size: 12px;
}
