@import 'styles/variables.scss';

.Header {
  color: $colorDarkGray;
  position: fixed;
  padding: 8px 30px;

  .htco-HeaderContainer {
    max-width: none;
    padding: 0 10px;
  }
}

.HeaderContentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.HeaderNav {
  padding-left: 75px;

  @include respond('md') {
    padding-left: 40px;
  }

  a {
    margin: 0 20px;
    color: $colorDarkGray;
    font-weight: bold;
    font-size: 14px;
    padding: 16px 5px;

    @include respond('md') {
      margin: 0 10px;
    }

    &:hover {
      color: $colorHometapBlue;
    }

    &.isActive {
      border-bottom: solid 3px $colorHometapBlue;
      padding-bottom: 14px;
      color: $colorPrimaryBlue100;
    }
  }

  &.isProd a {
    padding-bottom: 16px;
  }
}

.HeaderRightContentWrapper {
  display: flex;
}

.HeaderUserDropdown {
  vertical-align: sub;
  white-space: nowrap;

  .htco-MenuDropdownChevron::before {
    font-size: 16px;
    margin: auto 0 auto 8px;
  }
}
