@import 'styles/variables.scss';

.NewMergedDocumentContainer {
  margin-top: 26px;
}

.NewMergedDocumentTitle {
  padding: 14px 16px 9px 16px;
  border-radius: 12px 12px 0 0;
  border: 1px solid $colorNeutralLight100;
}

.NewMergedDocumentFields {
  border-radius: 0 0 12px 12px;
  border: 1px solid $colorNeutralLight100;
  border-top: none;
}

.NewMergedDocumentField {
  margin-right: 8px;
}

.NewMergedDocumentInputs {
  line-height: 62px;
  td {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.NewMergedDocumentHeaders {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  line-height: 32px;
  border-bottom: 1px solid $colorNeutralLight100;
  th {
    padding-left: 12px;
  }
}

.NewMergedDocumentFieldRadio {
  width: 205px;
}
