@import 'styles/variables.scss';

.PreviewDocumentLeftbar {
  width: 240px;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
  color: $colorPrimaryBlue100;
  // hide scroll bar
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.PreviewDocumentPageRotateButton {
  width: 20px;
  margin-left: 8px;
  margin-right: 8px;
}

.PreviewDocumentThumbnailItemRotationButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100px;
}

.PreviewDocumentThumbnailItem {
  cursor: pointer;
  padding: 8px;
  width: 100%;
}

.PreviewDocumentThumbnailItemThumbnail {
  margin-bottom: 8px;
  .rpv-thumbnail__container {
    width: fit-content !important;
    height: fit-content !important;
  }
}

.PreviewDocumentThumbnailPageLabel {
  font-size: 12px;
  font-weight: 700;
  color: $colorNeutralDark100;
  margin-left: 4px;
}
