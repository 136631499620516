@import 'styles/variables.scss';

.TaskDetailMultipleFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.TaskDetailMultipleFormsCardActionBlock {
  margin-top: 48px;
}

.TaskDetailMultipleFormsCardList {
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
