h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titleFont;
}

h1 {
  font-size: 38px;
  line-height: 52px;

  @include respond('sm') {
    font-size: 28px;
    line-height: 39px;
  }
}

h2 {
  font-size: 28px;
  line-height: 39px;

  @include respond('sm') {
    font-size: 22px;
    line-height: 28px;
  }
}

h3 {
  font-size: 24px;
  line-height: 34px;

  @include respond('sm') {
    font-size: 20px;
    line-height: 24px;
  }
}

h4 {
  font-size: 20px;
  line-height: 28px;

  @include respond('sm') {
    font-size: 16px;
    line-height: 22px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;

  @include respond('sm') {
    font-size: 14px;
    line-height: 22px;
  }
}

.text-body-lg {
  @include bodyFont('lg');
}

.text-body-sm {
  @include bodyFont('sm');
}

.text-list {
  list-style-type: none;

  > li {
    position: relative;
    left: 0.5em;

    &:before {
      content: '\00B7';
      position: absolute;
      left: -0.5em;
      color: $colorHometapBlue;
    }
  }
}

.text-highlighted {
  color: $colorHometapBlue;
  font-weight: 600;
}

.text-descriptive {
  @include bodyFont('sm');
  color: fade-out($colorDarkestNavy, 0.3);
  font-style: italic;
  font-weight: normal;
}
