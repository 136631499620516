@import 'styles/variables.scss';

$buttonSize: 28px;

@mixin controlButtonDefaults {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: calc(100% - #{$buttonSize} / 2);
  font-size: 6px;
  color: $colorHometapBlue;
  border-radius: 50%;
  width: $buttonSize;
  height: $buttonSize;
  background-color: $colorWhite;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  outline-width: inherit;
}

.TaskDrawerContainer {
  display: flex;
  position: relative;
  border-right: 1px solid $colorLightGray4;
  max-height: 100%;

  .htco-SlideSide {
    height: inherit;
  }

  &.isOpen {
    .TaskDrawerControlButtonIcon {
      transform: rotate(270deg) translateY(-2px);
    }
  }
}

.TaskDrawer {
  height: 100%;
  overflow-y: auto;
  transition: padding 0.3s ease;
}

.TaskDrawerControlButton {
  @include controlButtonDefaults();
  z-index: 2;
  left: calc(100% - #{calc($buttonSize / 2)});
}

.TaskDrawerControlOverlayButton {
  @include controlButtonDefaults();
  z-index: 100;
  transition-timing-function: ease;

  &.animateOpen {
    left: 18px + 398px - 32px;
  }
}

.TaskDrawerControlButtonIcon {
  transform: rotate(90deg);
}
