@import 'styles/variables.scss';

.TaskNote {
  background-color: $colorWhite;
  outline: 1px solid $colorNeutralLight100;
  transition: outline ease-in 200ms;
  line-height: 0;

  &.isActive {
    outline: 1px solid $colorPrimaryBlue100;
  }

  &.isDisabled {
    background-color: $colorNeutralLight50;
  }
}

.TaskNoteText {
  line-height: 26px;
  background-color: transparent;
  resize: none;
  border: none;
  padding: 16px;
  margin: 0;

  &:disabled {
    color: $colorNeutralDark30;
  }

  &.isActive {
    padding-top: 16px;
    padding-bottom: 0;
  }

  &:focus {
    border-color: transparent;
  }
}

.TaskNoteTitle {
  line-height: 24px;
  font-size: 12px;
  color: #58687d;
  margin: 4px 0;
}
