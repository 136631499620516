@import 'styles/variables.scss';

.FundAllocationHeaderContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.FundAllocationHeaderLabel {
  font-weight: 600;
  font-size: 28px;
  color: $colorNeutralDark100;
}

.FundAllocationHeaderFunds {
  min-height: 46px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  overflow-x: scroll;
}
