@import 'styles/variables.scss';

.GovernmentIDFormDatePicker {
  width: 100%;
  .react-datepicker-wrapper,
  .htco-DatePickerInput {
    width: inherit;
  }
}

.GovernmentIDAddHomeownerTodo {
  margin-top: -8px;
}
