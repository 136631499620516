@import 'styles/variables.scss';
@import 'apps/track-details/tasks/components/HtmlRenderer/HtmlRenderer.scss';

.TaskDetailLoadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TaskDetailContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.TaskDetailRecentChange {
  font-size: 12px;
  font-style: italic;
  color: $colorNeutralDark50;
  margin: 0;
  cursor: pointer;
}

.TaskDetailTooltip {
  .htco-Tooltip {
    inset: -11px auto auto 0px !important;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.8);
    padding: 4px 8px;
    color: $colorNeutralLight30;
  }

  .htco-Tooltip[data-popper-placement^='bottom'] .htco-TooltipArrow {
    &:before,
    &:after {
      display: none;
    }
  }
}

.TaskDetailBody {
  height: inherit;
  padding: 32px;
  overflow-y: scroll;
}

.TaskDetailMarginBlock {
  margin-top: 32px;

  ul {
    padding-left: 24px;
    line-height: 0;
    list-style: inside;
  }
}

.TaskDetailList:not(:first-of-type) {
  margin-top: 24px;
}

.TaskDetailFooter {
  display: flex;
  margin: 0;
  padding: 16px;
  box-shadow:
    0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.2);
}

.TaskDetailError {
  i {
    margin-left: 32px;
  }
}

.RiskBandMetaDescription {
  display: list-item;
  padding-left: 17px;
}
