body {
  text-rendering: optimizeLegibility;
  @apply m-0 overflow-auto bg-neutral-light-30 p-0 font-sans font-normal leading-6 text-neutral-dark-100 antialiased;

  &.isLightBackground {
    background: #fff;
  }

  &.focusWithMouse :focus {
    outline: none;
  }
}

p {
  margin: 20px 0;
}

hr {
  border: solid 1px $colorLightGray3;
}

table {
  width: 100%;
  border-collapse: collapse;
}

a {
  color: $colorLink;

  &:hover {
    opacity: 0.8;
  }
}

.htco-Button {
  -webkit-appearance: none;
}

#Main {
  position: relative;
  top: $headerHeight;
}

.FullPageLoader {
  max-width: 100%;
  height: calc(100vh - $headerHeight);
}

/*
The bit-notification-bar-spacer class name is used by Bitwarden to create space at the top of the page
for their notification bar. This class name is specific to Bitwarden and is used in their code,
so it is likely to remain the same as long as they continue to use this approach
*/
#bit-notification-bar-spacer {
  position: absolute;
}
