@import 'src/styles/variables.scss';

.ReviewPropertyTaxHeader {
  color: $colorNeutralDark100;
  font-size: 22px;
  font-weight: 700;
  line-height: 34px;
  margin: 40px 0 24px 0;
}
.ReviewPropertyTaxAnnualAmount {
  margin-top: 24px;
}

.ReviewPropertyTaxConditionalCopy {
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid $colorSecondaryYellow30;
  background: $colorSecondaryYellow100;
  color: $colorNeutralDark100;
  line-height: 26px;
}
