@import 'styles/variables.scss';

.FundingCellGrid {
  display: flex;
  flex-grow: 3;
  flex-direction: column;
}

.FundingCellContainer {
  margin-left: 0px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 3;
  gap: 10px;
}

.FundingCellDropdown {
  display: flex;
  flex-grow: 3;
  .FundingCellDropdownOptions {
    display: flex;
    flex-direction: row;
    padding: 0px;
  }
}

.FundingCellMuiSelect {
  width: 100%;
  text-anchor: start;
}

.FundingCellWarningText {
  line-height: 28px;
}

.fundingCellWarningDropDownLabel {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: 12px 16px;
  margin-right: 40px;
  border-radius: 12px;
  gap: 8px;
  font-size: 16px;

  i {
    padding-top: 8px;
  }

  &.ineligible {
    background-color: $colorSecondaryRed15;
    border: 1px solid $colorSecondaryRed100;
    i {
      color: $colorSecondaryRed100;
    }
  }
  &.newChanges {
    background-color: #fffcf2;
    border: 1px solid #ffbc03;
  }
}

.FundingCellTooltip::before {
  font-size: 28px;
}

.TooltipList {
  list-style: decimal;
  padding: 0 0 0 16px;
}

.TooltipContent {
  display: flex;
  flex-direction: column;
}

.IneligibleReasonsList {
  list-style-type: disc;
  padding-inline-start: 40px;
}

.FundingCellDropDownOptionsBadge {
  margin-right: 12px;
}
