.ApplicationReviewActionBar {
  display: flex;
  width: inherit;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  & > button.htco-Button {
    margin: 0;
    flex: 1 0 0;
    white-space: nowrap;
  }
}

.ApplicationReviewActionBarBottomMargin {
  margin-top: 8px;
  margin-bottom: 24px;
}

.SectionAlert {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  line-height: 17.57px;
  text-align: left;
  display: flex;
  font-weight: 400;

  & i.icon.htco-Icon {
    font-size: 16px;
    line-height: 17.57px;
  }
}

.SectionAlertIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
