@import 'styles/variables.scss';

.SelectorElement {
  width: 100%;
  & > .htco-Label {
    line-height: 26px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
.htco-SelectionSetItem {
  margin: 0;
}

.htco-SelectionSetWrapper {
  display: flex;
  gap: 16px;
}

.ReviewAppraisalCalloutContainer {
  border-radius: 6px;
  border: 1px solid $colorPrimaryBlue15;
  font-size: 16px;
  align-items: center;
  padding: 16px 24px;

  &.ErrorContainer {
    border: 1px solid $colorSecondaryRed30;
    background-color: $colorSecondaryRed15;

    .htco-DetailBoxIcon {
      color: $colorSecondaryRed100;
    }
  }
}
