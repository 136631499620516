.DocumentSetBatchControllerPadding {
  padding-top: 20px;
  background-color: white;
  min-height: 100vh;
}

.DocumentSetBatchControllerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DocumentSetBatchContainer {
  max-width: 1800px;
}

.DocumentSetBatchTableIncludedDocumentSets {
  padding-left: 8px;
}

.DocumentSetBatchControllerGetMoreBatchesButton {
  margin-left: 50%;
}
