@import 'styles/variables.scss';

.FileUploader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 240px;
  margin-top: 40px;
  margin-bottom: 24px;
  border-radius: 16px;
  outline: 2px dashed $colorBorder !important;
  cursor: pointer;

  &.isDisabled {
    background: $colorLightGray1;
    opacity: 0.5;
    cursor: default;
  }
}

.FileUploaderIcon {
  color: $colorHometapBlue;
  margin-bottom: 32px;
}

.FileUploaderCTA {
  font-size: 18px;
  line-height: 29px;
}

.FileUploaderCTAHighlight {
  color: $colorHometapBlue;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.FileUploaderAdditionalInfo {
  font: 14px;
  color: $colorMediumGray2;
  line-height: 23px;
  margin-top: 8px;
}
