@import 'styles/variables.scss';

.ErrorBlockContainer {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  margin: 24px;
  min-width: 400px;
  gap: 16px;
  align-self: stretch;
  border: 1px solid $colorSecondaryRed30;
  background-color: $colorSecondaryRed15;
  border-radius: 6px;
}

.ErrorBlockIcon {
  margin-top: 4px;
  color: $colorNeutralDark100;
}

.ErrorBlockContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

  a {
    text-decoration: underline 1px;
    text-underline-offset: 2px;
  }
}
