@import 'styles/variables.scss';

.DocumentFileListCombinedText {
  margin-top: 8px;
  font-size: 16px;
  color: $colorNeutralDark50;
}

.DocumentFileListTableContainer {
  border: 1px solid $colorNeutralLight100;
  border-radius: 12px;

  .htco-DataTable {
    width: 100%;
    overflow: scroll;
    margin-bottom: 8px;

    .rdt_Table {
      min-height: 100px;
    }
  }
}

.DocumentFileListTableContainerDivider {
  width: 100%;
}

.DocumentFileListTableContainerHeader {
  padding-top: 16px;
  padding-left: 16px;
}

.DocumentFileListFileInfoContainer {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.DocumentFileListRemoveFileContainer {
  background-color: $colorNeutralLight50;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: 0.25s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $colorNeutralLight100;
  }

  i {
    min-width: 12px;
    color: $colorNeutralDark30;
  }
}

.DocumentFileList {
  .htco-Checkbox {
    margin-top: 26px;
  }
}

.DocumentFileListPasswordProtectedBadge {
  margin: 0;
  position: unset;
}

.DocumentFileListMergeWarningBox {
  background-color: $colorSecondaryYellow15;
  border-radius: 6px;
  border: 1px solid $colorSecondaryYellow30;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  margin-top: 16px;
}

.DocumentFileListMergeCheckbox {
  label {
    margin-top: 24px;
  }
}
