@import 'styles/variables.scss';

.InvestmentCalcOutput {
  border-radius: 12px;
  border-style: solid;
  border-color: $colorNeutralLight100;
  border-width: 1px;
  position: relative;
  background: $colorNeutralLight30 !important;
}

.InvestmentCalcOutputRow {
  padding-top: 10px;
}

.InvestmentCalcOutputDataWrapper {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.InvestmentCalcOutputDataValue {
  font-weight: 700;
}
