@import 'styles/variables.scss';

.PreviewDocumentSidebar {
  min-width: 350px;
  border-left: 1px solid lightgray;
  overflow-x: hidden;
  overflow-y: scroll;

  &.loaderContainer {
    display: flex;
    justify-content: center;
    padding-top: 8px;
  }
}

.PreviewDocumentSidebarError {
  &.htco-FormError {
    padding-left: 24px;
  }
}

.PreviewDocumentSidebarDivider {
  background: $colorNeutralLight100;
  height: 1px;
  width: 100%;
}

.PreviewDocumentSidebarDownloadContainer {
  height: 104px;

  &.shouldHide {
    display: none;
  }
}

.PreviewDocumentSidebarDownload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.PreviewDocumentSidebarDownloadContainerButton {
  font-weight: 700;
  font-size: 16px;
  width: 80%;
}

.PreviewDocumentSidebarIndividualOtherVersion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.currentVersion {
    background-color: $colorPrimaryBlue15;
  }
}

.PreviewDocumentSidebarTitle {
  margin-left: 24px;
  margin-top: 24px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  mix-blend-mode: normal;
  color: $colorNeutralDark100;
}

.PreviewDocumentSidebarVersionTitle {
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: left;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: $colorNeutralDark100;
  mix-blend-mode: normal;
}

.PreviewDocumentSidebarCurrentVersionContainer {
  &.currentVersion {
    background-color: $colorPrimaryBlue15;
  }

  width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
}

.PreviewDocumentSidebarCurrentVersionContainerList {
  margin-left: 24px;
  padding-top: 8px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  font-size: 12px;
  font-size: 16px;
  margin-bottom: 16px;
  width: 80%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;

  li {
    text-align: left;

    &:first-child {
      color: $colorPrimaryBlue100;
      font-weight: 700;
    }

    &:nth-child(n + 2) {
      color: $colorNeutralDark50;
    }
  }

  a {
    text-align: left;

    &:first-child {
      color: $colorPrimaryBlue100;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &:nth-child(n + 2) {
      color: $colorNeutralDark50;
    }
  }
}

.PreviewDocumentSidebarIndividualOtherVersionDropDown {
  font-weight: 500;
  width: 50px;

  i {
    color: $colorPrimaryBlue100;
    margin-right: 8px;
  }
}

.PreviewDocumentSidebarVersionDivider {
  width: 90%;
  background: $colorNeutralLight100;
  height: 1px;
}

.previewDocumentSidebarCurrentVersionContainerLoader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px;
}

.Toastify__toast-container {
  overflow-y: unset !important;
}
