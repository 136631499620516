@import 'styles/variables.scss';

.TaskGroupItemWrapper {
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    border-bottom: 1px solid $colorNeutralLight100;
    display: block;
    margin: 0 auto;
  }

  &:not(.disabled):hover {
    background-color: $colorNeutralLight50;
  }

  &:focus {
    border: 1px solid $colorPrimaryBlue100;

    &:after {
      display: none;
    }
  }

  &.isActive {
    background-color: #e1e9fc;
  }

  &.disabled {
    cursor: not-allowed;

    .TaskGroupItemHeader {
      color: $colorNeutralDark50;
    }
  }
}

.TaskGroupItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.TaskGroupItemHeader {
  color: $colorNeutralDark100;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TaskGroupItemBadge {
  margin-right: 17px;
  white-space: nowrap;
  flex-shrink: 0;
}

.TaskGroupItemBadgeTooltipIcon {
  height: 24px;
  margin-left: 8px;
  margin-right: 12px;
  & > span {
    height: 24px;
    display: inline-block;
  }
}

.TaskGroupItemHasNotesIcon {
  position: absolute;
  right: 6px;
  top: 4px;

  &::before {
    font-size: 10px;
  }
}
