@import 'styles/variables.scss';

.PreviewFile {
  width: 100%;
  height: 100%;
  background-color: $colorNeutralLight50;
  &.isEmbedded {
    border-left: 1px solid $colorNeutralLight100;
    border-right: 1px solid $colorNeutralLight100;
  }
  &.isFullScreenEmbedded {
    padding-top: inherit;
    width: inherit;
    margin: auto;
    display: block;
    height: auto;
    border: none;
  }
}

.CenterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.isTile {
    height: 80%;
  }
}

.PreviewImage {
  padding-top: 3%;
  overflow: hidden;
  &.isScrollEnabled {
    overflow: scroll;
  }

  color: $colorNeutralDark100 img {
    max-width: 100%;
    max-height: 100%;
    margin: 20px;
  }

  > img {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.PreviewPDF {
  .rpv-core__inner-pages {
    padding-top: 20px;
  }
  .rpv-core__inner-page {
    padding-top: 2px;
    background-color: $colorNeutralLight50;
  }
}

.PreviewFileName {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.PreviewFileSize {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.PreviewDocumentTile .PinnedIcon {
  color: $colorPrimaryBlue100;
  font-size: 24px;
  position: absolute;
}
