@import 'styles/variables.scss';

.VisibilityRadioRow {
  display: flex;
  flex-direction: row;
  margin-left: -12px;
  width: 205px;
}

.VisibilityRadioRowRadio {
  padding-left: 8px;

  &.disabled i {
    color: $colorNeutralDark30;
  }

  i {
    padding: 2px;
    color: $colorPrimaryBlue100;
  }
}
