@import 'styles/variables.scss';

@mixin actionBarButtonDefaults {
  height: 40px;
  line-height: 22px;
  padding: 9px 24px;
  font-size: 14px;
  border-radius: 6px;
  margin: 0;
}

.TaskActionBar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 24px;
}

.TaskActionBarButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
}

.TaskActionBarButton {
  @include actionBarButtonDefaults();
  margin-left: 0 !important;

  &:hover {
    padding: 9px 24px;
    border: 1px solid $colorPrimaryBlue100 !important;
  }
}

.TaskActionBarDemoButton {
  position: relative !important;
  transform: unset !important;
  bottom: unset !important;
  left: unset !important;

  & > button {
    @include actionBarButtonDefaults();

    &:hover {
      padding: 9px 24px;
    }
  }
}

.TaskActionBarInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font: italic 14px/18px Mulish;
}
